<template>
	<div class="bg-white md:border border-primary p-4 md:p-8 w-full md:w-10/12 2xl:w-3/5">
		<h1>{{ config.booking.event_name }}</h1>
		<p class="italic">{{ config.booking.organization }}</p>
		<h2>{{ texts.ratingTitle }}</h2>
		<p class="mb-2">{{ texts.ratingDescription }}</p>
		<div class="flex flex-col border border-primary p-4 divide-y divide-defaultborder">
			<template v-for="{id, code} in config.ratings">
				<div class="pb-2 pt-4 sm:flex justify-between">
					<div class="pr-2 flex-shrink md:flex-shrink-0">
						<label>{{ code }}</label>
					</div>
					<div class="flex-shrink-0 star-width pr-2">
						<div>
							<star-rating-form-field
									:model-value="rawRatings[id]"
									@update:model-value="updateRatingValue(id, $event)"
							/>
						</div>
					</div>
				</div>
			</template>
			<ErrorMessagesDisplay :error="error('rawRatings')"></ErrorMessagesDisplay>
		</div>
		<h2 class="mt-8">{{ texts.personalReviewTitle }}</h2>
		<div class="review-fieldrow">
			<label>{{ texts.titleLabel }}</label>
			<input
					v-model="form.title"
					class="w-full"
					placeholder=""
					type="text"
			>
			<ErrorMessagesDisplay :error="error('title')"></ErrorMessagesDisplay>
		</div>
		<div class="review-fieldrow">
			<label>{{ texts.detailLabel }}</label>
			<textarea
					v-model="form.detail"
					class="w-full"
					placeholder=""
					rows="6"
			/>
		</div>
		<ErrorMessagesDisplay :error="error('detail')"></ErrorMessagesDisplay>
		<div class="review-fieldrow">
			<label>{{ texts.nicknameLabel }}</label>
			<input
					v-model="form.nickname"
					class="w-full"
					placeholder=""
					type="text"
			>
			<ErrorMessagesDisplay :error="error('nickname')"></ErrorMessagesDisplay>
		</div>
		<div class="review-fieldrow">
			<button
					:disabled="submitting" class="w-full button button-orange uppercase text-xl"
					@click="submit">{{ texts.submitButtonLabel }}
			</button>
		</div>
	</div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue'
import useVuelidate from '@vuelidate/core'
import {ReviewConfigInterface, ReviewFormInterface} from '@/js/interfaces/review'
import StarRatingFormField from "@/js/components/ui/StarRatingFormField.vue"
import {helpers, minLength, required} from "@vuelidate/validators"
import {addReview} from "@/js/api/backendSp"
import {useFieldError} from "@/js/composables/vuelidate"
import ErrorMessagesDisplay from "@/js/components/ui/ErrorMessagesDisplay.vue"

const texts = {
	globalDescription: 'Sie haben diese Veranstaltung kürzlich besucht. Wir freuen uns, wenn Sie uns Ihre Erfahrungen mitteilen.',
	ratingTitle: 'Ihre Benotung der Veranstaltung',
	ratingDescription: 'Welche Bewertung würden Sie den folgenden Aspekten der Veranstaltung geben?',
	personalReviewTitle: 'Ihre persönliche Bewertung',
	personalReviewDescription: 'Bitte geben Sie hier Ihre persönliche Bewertung der Veranstaltung ein.',
	titleLabel: 'Ihre Rückmeldung zur Veranstaltung in ein paar kurzen Worten (Titel der Bewertung):',
	detailLabel: 'Bitte beschreiben sie in zwei, drei Sätzen Ihren persönlichen Eindruck von der Veranstaltung. Also z.B.: Was hat Ihnen besonders gefallen? Was war vielleicht weniger gut? Wem würden Sie den Besuch empfehlen, usw.',
	nicknameLabel: 'Bitte geben Sie den Namen ein, unter dem wir Ihre Rückmeldung veröffentlichen können. Falls Sie lieber anonym bleiben wollen, können Sie gerne auch einen Fantasienamen angeben.',
	submitButtonLabel: 'Absenden',
}
const messages = {
	ratingsRequired: 'Bitte geben Sie eine Bewertung für alle Aspekte der Veranstaltung an.',
	required: 'Dieses Feld ist ein Pflichtfeld.',
	validationError: 'Da scheint etwas noch nicht ganz zu stimmen. Bitte prüfen Sie die Eingabefelder...',
	submissionError: 'Bei der Übermittlung Ihrer Bewertung ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
}

const ratingsMustBeFilled = (ratings) => {
	for (const rating in ratings) {
		if (ratings[rating] === 0) {
			return false
		}
	}
	return true
}

export default defineComponent({
	name: 'EventReviewForm',
	components: {StarRatingFormField, ErrorMessagesDisplay},
	setup: () => ({v$: useVuelidate()}),
	props: {
		config: {
			type: Object as PropType<ReviewConfigInterface>,
			required: true,
		},
	},
	data() {
		return {
			texts: texts,
			form: {
				form_key: this.config.formKey,
				id: this.config.booking.event_id,
				ratings: {},
				detail: '',
				title: '',
				nickname: this.config.booking.name + (this.config.booking.job ? ' (' + this.config.booking.job + ')' : ''),
				review_token: this.config.reviewQueueItem.token
			} as ReviewFormInterface,
			rawRatings: {},
			submitting: false,
		}
	},
	mounted() {
		this.config.ratings.forEach(rating => {
			this.rawRatings[rating.id] = 0
		})
	},
	methods: {
		error(prop: string) {
			return useFieldError(prop, this.errors)
		},
		updateRatingValue(id: number, value: number) {
			this.rawRatings[id] = value
		},
		getRatingById(id: number) {
			return this.config.ratings.filter(rating => {
				return rating.id === id
			})[0]
		},
		getRatingOptionIdByValue(id: number, value: number) {
			return this.getRatingById(id).options.filter(option => {
				return option.value === value
			})[0].id
		},
		replaceRatingValues() {
			this.form.ratings = {}
			for (const rating in this.rawRatings) {
				this.form.ratings[rating] = this.getRatingOptionIdByValue(parseInt(rating), this.rawRatings[rating])
			}
		},
		async submit() {
			this.submitting = true
			const result = await this.v$.$validate()
			if (result) {
				try {
					this.replaceRatingValues()
					const data = await addReview(this.form)
					this.$toast.success(data)
					this.submitting = false
					setTimeout(() => {
						window.location.href = '/'
					}, 5000)
				} catch (e: any) {
					if (e.response && e.response.data) {
						this.$toast.error(e.response.data)
					} else {
						this.$toast.error(messages.submissionError)
					}
				} finally {
					this.submitting = false
				}
			} else {
				this.$toast.error(messages.validationError)
				this.submitting = false
			}
		}
	},
	computed: {
		errors() {
			return this.v$.$errors
		},
	},
	validations() {
		return {
			form: {
				title: {
					required: helpers.withMessage(messages.required, required),
					minLength: helpers.withMessage(({$params}) => `Bitte geben Sie mindestens ${$params.min} Zeichen ein.`, minLength(5)),
				},
				detail: {
					required: helpers.withMessage(messages.required, required),
					minLength: helpers.withMessage(({$params}) => `Bitte geben Sie mindestens ${$params.min} Zeichen ein.`, minLength(10)),
				},
				nickname: {
					required: helpers.withMessage(messages.required, required),
					minLength: helpers.withMessage(({$params}) => `Bitte geben Sie mindestens ${$params.min} Zeichen ein.`, minLength(3)),
				}
			},
			rawRatings: {
				ratingsMustBeFilled: helpers.withMessage(messages.ratingsRequired, ratingsMustBeFilled),
			}
		}
	},

})
</script>

<style scoped>
.review-fieldrow {
	@apply my-4;
}

.star-width {
	width: 150px
}

label {
	display: block;
	@apply mb-2;
}

input {
	@apply w-full;
}
</style>
