import {VueAddToCartConfigInterface} from "@/js/interfaces/cart"
import {BookedTicketInterface, TicketInterface} from "@/js/interfaces/ticket"

export function useTaxInfoString(config: VueAddToCartConfigInterface | TicketInterface | BookedTicketInterface) {
    if (!Object.prototype.hasOwnProperty.call(config, "event") || config.event === undefined) return null
    const taxInfoConfig = config.event.tax_info
    if (!taxInfoConfig.is_taxable) {
        return null
    }
    if (taxInfoConfig.price_includes_tax) {
        return "inkl. gesetzl. MWST"
    }
    return "zuzügl. gesetzl. MWST"
}
