import {inject} from "vue"
import Cookies from 'js-cookie'

const cookieApi = Cookies.withAttributes({
    sameSite: 'lax',
    path: '/',
    domain: import.meta.env.VITE_COOKIE_DOMAIN,
})

export interface PlausibleInterface {
    trackEvent: (event: string, options?: any) => void;
    trackPageview: (options?: any) => void;
}

interface TrackingInterface {
    plausible?: PlausibleInterface;
    pageView: () => void
    error: (msg: string) => void
    cart: (action: string, label: string, value?: number) => void
    social: (network: string, label: string, value?: number) => void
    account: (action: string, label: string, value?: number) => void
    search: (query: string, resultCount?: number) => void
    list: (action: string, label: string) => void
    buttonClick: (label: string, value?: number) => void
    linkClick: (url: string, value?: number) => void
    modalOpen: (title: string) => void
    modalClose: (title: string) => void
    formInput: (field: string, value?: number) => void
    campaignClick: (campaign: string, identifier: string, value?: number) => void
    trackAction: (category: string, action: string, label: string, value?: number) => void
}

function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

function trackingAllowed() {
    if (cookieApi.get('tracking') === 'false') {
        return false
    }
    if (cookieApi.get('admin') === 'true') {
        console.log('Tracking disabled for admin users')
        return false
    }
    return true
}

export const useTracking = (): TrackingInterface => {
    const isAdmin = !!cookieApi.get('adminhtml')
    const plausible = isAdmin ? undefined : inject('$plausible') as PlausibleInterface
    const pageView = (): void => {
        if (plausible) {
            const {trackPageview} = plausible
            trackPageview()
        }
    }
    const trackAction = (category: string, action: string, label: string, value = 0): void => {
        if (plausible && trackingAllowed()) {
            const {trackEvent} = plausible
            trackEvent(`${capitalizeFirstLetter(category)}: ${capitalizeFirstLetter(action)}`, {
                props: {
                    label,
                    value
                }
            })
        }
    }
    const error = (msg: string): void => {
        trackAction("Error", "Thrown", msg, 0)
    }
    const cart = (action: string, label: string, value = 0): void => {
        trackAction("Cart", action, label, value)
    }
    const social = (network: string, label: string, value = 0): void => {
        trackAction("Social", network, label, value)
    }
    const account = (action: string, label: string, value = 0): void => {
        trackAction("Account", action, label, value)
    }
    const search = (query: string, resultCount?: number): void => {
        let label = "Search for " + query
        if (resultCount) {
            label += ' yielded ' + resultCount + ' results'
        }
        trackAction("Search", "Query", label)
    }
    const list = (action: string, label: string): void => {
        trackAction("List", "Change", label)
    }
    const buttonClick = (label: string, value = 0): void => {
        trackAction("Button", "Click", label, value)
    }
    const linkClick = (url: string, value = 0): void => {
        trackAction("Link", "Click", url, value)
    }
    const modalOpen = (title: string): void => {
        trackAction("Modal", "Open", title)
    }
    const modalClose = (title: string): void => {
        trackAction("Modal", "Close", title)
    }
    const formInput = (field: string, value = 0): void => {
        trackAction("Form", "Input", field, value)
    }
    const campaignClick = (campaign: string, identifier: string, value = 0): void => {
        trackAction("Campaign " + campaign, "Click", "Campaign click " + identifier, value)
    }
    return {
        plausible,
        trackAction,
        pageView,
        error,
        cart,
        social,
        account,
        search,
        list,
        buttonClick,
        linkClick,
        modalOpen,
        modalClose,
        formInput,
        campaignClick,
    }
}
