<template>
	<div>
		<div v-if="$slots['opener']" class="link" @click="showModal">
			<slot name="opener"></slot>
		</div>
		<transition name="modal-fade">
			<div
					v-if="modalShow"
					class="fixed inset-0 w-full h-screen flex items-center justify-center bg-black bg-opacity-75 overflow-auto z-50"
					@ok="emit('ok')"
			>
				<div
						class="w-full max-w-4xl bg-white shadow-lg rounded relative overflow-hidden m-auto"
						v-on-clickaway="hideModal"
				>
					<div
							v-if="$slots['title']"
							class="flex bg-header px-8 py-4 justify-between text-2xl font-bold"
					>
						<div>
							<slot name="title"></slot>
						</div>
						<button
								aria-label="close"
								type="button"
								class="outline-none hover:outline-none"
								@click="hideModal"
						>
							×
						</button>
					</div>
					<div v-else>
						<button
								aria-label="close"
								class="absolute top-0 right-0 text-xl text-gray-500 my-2 mx-4 active:outline-none focus:outline-none"
								type="button"
								@click="hideModal"
						>
							×
						</button>
					</div>
					<div class="p-8">
						<slot/>
					</div>
					<div class="border-t border-header px-8 py-4 justify-between">
						<slot name="footer">
							<div
									:class="okButtonText ? 'justify-between' : 'justify-end'"
									class="py-4 flex"
							>
								<button
										:class="
                okButtonText
                  ? 'float-left bg-gray-600'
                  : 'float-right bg-secondary'
              "
										type="button"
										class="rounded text-white px-4 py-2 shadow"
										@click="hideModal"
								>
									Schliessen
								</button>
								<button
										v-if="okButtonText"
										type="button"
										class="float-right ml-4 rounded bg-secondary text-white px-4 py-2 shadow"
										@click.stop.prevent="confirm"
								>
									{{ okButtonText }}
								</button>
							</div>
						</slot>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>
<script lang="ts" setup>

import {ref, useSlots} from "vue"

import {directive as onClickaway} from "vue3-click-away"

const vOnClickaway = onClickaway

const props = withDefaults(defineProps<{
	okButtonText?: string
	closeOnConfirm?: boolean
}>(), {
	closeOnConfirm: true
})

const emit = defineEmits(['close', 'ok'])

const slots = useSlots()

const modalShow = ref(!slots.opener)

const showModal = () => {
	document.body.classList.add("modal-open")
	modalShow.value = true
}

const hideModal = () => {
	document.body.classList.remove("modal-open")
	modalShow.value = false
	emit('close')
}

const confirm = () => {
	emit('ok')
	if (props.closeOnConfirm) {
		hideModal()
	}
}

defineExpose({
	showModal,
	hideModal
})
</script>
<!--suppress CssUnusedSymbol -->
<style>
.modal-fade-enter,
.modal-fade-leave-active {
	opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
	transition: opacity 0.5s ease;
}
</style>
