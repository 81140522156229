<template>
	<div>
		<transition name="fade">
			<div
					v-if="props.error"
					:class="'border-' + twColor + ' text-' + twColor + ' bg-' + twBgColor"
					class="border mt-1 py-1 px-4 text-sm shadow-md"
			>
				<ul v-if="Array.isArray(props.error)" data-testid="error-list">
					<li v-for="(item, index) in error" :key="index">{{ item }}</li>
				</ul>
				<div v-else data-testid="single-error">
					{{ error }}
				</div>
			</div>
		</transition>
	</div>
</template>

<script lang="ts" setup>
const props = withDefaults(defineProps<{
	error?: string | string[] | null,
	twColor?: string,
	twBgColor?: string
}>(), {
	twColor: "red-500",
	twBgColor: "red-200"
})
</script>

<!--suppress CssUnusedSymbol -->
<style scoped>
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
	opacity: 0;
}
</style>
