<template>
	<div class="flex flex-col">
		<div
				class="cursor-pointer mt-8 w-full bg-accent-orange border border-transparent px-5 py-3 inline-flex items-center justify-center text-base font-medium rounded-md text-white hover:bg-hover-orange hover:text-white sm:mt-10 sm:w-auto xl:mt-0 shadow-md"
				@click="scrollToRegisterForm"
		>
			Jetzt Partner werden
		</div>
	</div>
</template>

<script lang="ts" setup>
const scrollToRegisterForm = () => {
	const registerFormElement = document.getElementById('registerform')!
	registerFormElement.scrollIntoView({behavior: "smooth"})
}
</script>

<style scoped></style>
