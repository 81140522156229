<template>
	<div class="card card-blue mb-4">
		<div class="block-title card-header">
			<strong><span class="text-white">Newsletter abonnieren</span></strong>
		</div>
		<div class="card-body white std">
			<div class="fieldset">
				<div class="fieldrow">
					<input
							v-model="form.email"
							name="email"
							placeholder="hans@muster.ch"
							type="email"
							autoComplete="email"
					/>
				</div>
			</div>
			<UiModal ref="subscriptionModal"
			         :close-on-confirm="false"
			         ok-button-text="Anmelden"
			         @ok="submit">
				<template v-slot:title>Themenauswahl und Anmeldung</template>
				<template v-slot:opener>
					<button class="button-orange w-full">
            <span class="mr-2">
              <font-awesome-icon icon="envelope"></font-awesome-icon>
            </span>
						Themenauswahl
					</button>
				</template>
				<p class="mb-2">
					Zu welchen Themen möchten Sie in Zukunft Informationen zu aktuellen
					und neuen Seminaren und Kursen erhalten?*.
				</p>
				<div class="field-row mb-4">
					<CheckboxGroup
							v-model="form.selectedSegments"
							:options="displaySegments"
							class="mb-4 grid grid-cols-3"
					>
					</CheckboxGroup>
					<ErrorMessagesDisplay
							:error="getFieldError('selectedSegments')"
					></ErrorMessagesDisplay>
				</div>
				<p
						class="text-sm mb-4 bg-accent-green border border-green-800 p-4 text-white rounded"
				>
					* Wir respektieren Ihren Willen. Sie können sich in jedem Newsletter
					mit einem einzigen Klick wieder abmelden.
				</p>

				<div class="fieldset">
					<div class="fieldrow mb-4">
						<label class="hidden lg:tw-block">Herr / Frau</label>
						<div class="mt-1 relative shadow-sm">
							<select id="gender" v-model="form.gender" name="gender">
								<option value="m">Herr</option>
								<option value="f">Frau</option>
							</select>
						</div>
					</div>
					<div class="fieldrow mb-4 lg:flex lg:space-x-2">
						<div class="lg:w-1/2 mb-4 lg:mb-0">
							<label class="hidden lg:tw-block" for="firstname">Vorname</label>
							<div class="mt-1 relative shadow-sm">
								<input
										id="firstname"
										v-model="form.firstname"
										:class="
                    getFieldError('firstname')
                      ? 'border-red-500'
                      : ''
                  "
										autocomplete="given-name"
										name="lastname"
										placeholder="Hans"
										type="text"
								/>
								<ErrorExclamationMark
										v-if="getFieldError('firstname')"
								></ErrorExclamationMark>
							</div>
							<ErrorMessagesDisplay
									:error="getFieldError('firstname')"
							></ErrorMessagesDisplay>
						</div>
						<div class="lg:w-1/2 mb-4 lg:mb-0">
							<label class="hidden lg:tw-block" for="lastname">Nachname</label>
							<div class="mt-1 relative shadow-sm">
								<input
										id="lastname"
										v-model="form.lastname"
										:class="getFieldError('lastname') ? 'border-red-500' : ''"
										autocomplete="family-name"
										name="lastname"
										placeholder="Muster"
										type="text"
								/>
								<ErrorExclamationMark
										v-if="getFieldError('lastname')"
								></ErrorExclamationMark>
							</div>
							<ErrorMessagesDisplay
									:error="getFieldError('lastname')"
							></ErrorMessagesDisplay>
						</div>
					</div>
					<div class="fieldrow mb-4">
						<label class="hidden lg:tw-block" for="email">E-Mail</label>
						<div class="mt-1 relative shadow-sm">
							<input
									id="email"
									v-model="form.email"
									:class="
                  getFieldError('email') ? 'border-red-500' : ''
                "
									autocomplete="email"
									name="email"
									placeholder="hans@muster.ch"
									type="email"
							/>
							<ErrorExclamationMark
									v-if="getFieldError('email')"
							></ErrorExclamationMark>
						</div>
						<ErrorMessagesDisplay
								:error="getFieldError('email')"
						></ErrorMessagesDisplay>
					</div>
					<div class="fieldrow">
						<Captcha @input="setRecaptachaCode"></Captcha>
						<ErrorMessagesDisplay
								:error="recaptchaError"
						></ErrorMessagesDisplay>
					</div>
				</div>
			</UiModal>
		</div>
	</div>
</template>

<script lang="ts" setup>
import UiModal from "../ui/UiModal.vue"
import ErrorExclamationMark from "../ui/ErrorExclamationMark.vue"
import ErrorMessagesDisplay from "../ui/ErrorMessagesDisplay.vue"
import useVuelidate, {ErrorObject} from "@vuelidate/core"
import {subscribeToNewsletter} from "@/js/api/sp"
import axios from "axios"
import {useFieldError} from "@/js/composables/vuelidate"
import {email, helpers, required} from "@vuelidate/validators"
import CheckboxGroup from "@/js/components/ui/CheckboxGroup.vue"
import {
	NewsletterConfigInterface,
	NewsletterFormInterface,
	NewsletterSegmentItemInterface
} from "@/js/interfaces/newsletter"
import {useTracking} from "@/js/composables/plausible"
import {computed, inject, reactive, ref} from "vue"
import {ToastPluginApi} from "vue-toast-notification"
import Captcha from "@/js/components/ui/Captcha.vue"

const toast = inject<ToastPluginApi>("toast")!

const tracking = useTracking()

const messages = {
	required: 'Dies ist ein Pflichtfeld',
	email: 'Bitte geben Sie eine gültige E-Mail-Adresse ein',
	segments: 'Sie müssen mindestens ein Thema auswählen',
	captcha: 'Sie müssen das reCAPTCHA-Feld ausfüllen'
}

const props = defineProps<{
	config: NewsletterConfigInterface
}>()

const subscriptionModal = ref()

const form = reactive<NewsletterFormInterface>({
	email: null,
	gender: "m",
	firstname: null,
	lastname: null,
	selectedSegments: [],
	humanCheck: null,
})

const captchaError = ref<boolean>(false)

const formValidationRules = {
	firstname: {required: helpers.withMessage(messages.required, required)},
	lastname: {required: helpers.withMessage(messages.required, required)},
	email: {
		required: helpers.withMessage(messages.required, required),
		email: helpers.withMessage(messages.email, email)
	},
	selectedSegments: {
		required: helpers.withMessage(messages.segments, required)
	}
}

const formValidation = useVuelidate(formValidationRules, form)

const errors = computed((): ErrorObject[] => {
	return formValidation.value.$errors
})

const displaySegments = computed(() => {
	let segments: NewsletterSegmentItemInterface[] = JSON.parse(JSON.stringify(props.config.segments))
	return segments.map((segment: NewsletterSegmentItemInterface) => {
		return {
			id: segment.id,
			title: segment.title
		}
	})
})

const recaptchaError = computed(() => {
	if (captchaError.value) {
		return messages.captcha
	}
	return null
})

const getFieldError = (propertyName: string) => {
	return useFieldError(propertyName, errors.value)
}

const setRecaptachaCode = (code: string) => {
	captchaError.value = false
	form.humanCheck = code
}

const submit = async () => {
	const result = await formValidation.value.$validate()
	if (result) {
		let infoToast = toast.info(
				"Ihre Newsletter-Anmeldung wird übermittelt..."
		)
		try {
			await subscribeToNewsletter(form, props.config.signup_url)
			infoToast.dismiss()
			toast.success(
					"Sie sind jetzt für den Newsletter zu den gewählten Themen angemeldet."
			)
			form.email = null
			form.firstname = null
			form.lastname = null
			form.selectedSegments = []
			form.humanCheck = null
			captchaError.value = false
			tracking.trackAction('Newsletter', 'Signup', 'Successfully signed up for newsletter')
			subscriptionModal.value.hideModal()
		} catch (e) {
			if (axios.isAxiosError(e) && e.response) {
				if (e.response.data === "recaptcha error") {
					infoToast.dismiss()
					tracking.error('Newsletter signup failed due to recaptcha error')
					toast.error(
							"Das Recaptcha für den Spam-Check ist nicht gültig."
					)
					captchaError.value = true
				} else {
					infoToast.dismiss()
					tracking.error('Newsletter signup failed due to unknown error')
					toast.error(
							"Bei der Übermittlung der Anmeldung ist ein Fehler passiert. Bitte kontaktieren Sie uns über das Kontaktformular."
					)
				}
			} else {
				infoToast.dismiss()
				tracking.error('Newsletter signup failed due to unknown error')
				toast.error(
						"Bei der Übermittlung der Anmeldung ist ein Fehler passiert. Bitte kontaktieren Sie uns über das Kontaktformular."
				)
			}
		}
	} else {
		tracking.error('Newsletter signup failed due to validation error')
		toast.error(
				"Bitte prüfen Sie die Feldeingaben...da stimmt etwas noch nicht ganz."
		)
	}
}
</script>
