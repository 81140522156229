<template>
	<div data-testid="option">
		<div class="text-primary text-md bold uppercase mb-2" data-testid="options-title">
			{{ option.title }}
			<RequiredStar :modelValue="option.is_required"></RequiredStar>
		</div>
		<div class="space-y-2">
			<template v-if="option.type === 'checkbox'">
				<Checkbox
						v-for="value in option.values"
						:id="value.id"
						:key="value.id"
						v-model="selected[value.id]"
						data-testid="checkbox"
				>
					<div class="flex justify-between">
						<div>{{ value.title }}</div>
						<div>
							<PriceAlert :value="value" class="text-right"></PriceAlert>
						</div>
					</div>
				</Checkbox>
			</template>
			<RadioGroup
					v-if="option.type === 'radio'"
					:radio-values="option.values"
					:required="option.is_required"
					@update:modelValue="selectRadio"
			></RadioGroup>
			<ErrorMessagesDisplay :error="getFieldError('selected')"></ErrorMessagesDisplay>
		</div>
	</div>
</template>

<script lang="ts" setup>
import {computed, reactive, watch} from "vue"
import {EventOptionInterface, selectedOptionInterface} from "@/js/interfaces/option"
import RequiredStar from "@/js/components/ui/RequiredStar.vue"
import Checkbox from "@/js/components/ui/Checkbox.vue"
import PriceAlert from "@/js/components/ui/PriceAlert.vue"
import {useEventOptionValue} from "@/js/components/checkout/cart/composables/option"
import RadioGroup from "@/js/components/ui/RadioGroup.vue"
import useVuelidate from "@vuelidate/core"
import ErrorMessagesDisplay from "@/js/components/ui/ErrorMessagesDisplay.vue"
import {useFieldError} from "@/js/composables/vuelidate"
import {helpers} from "@vuelidate/validators"

const props = defineProps<{
	modelValue?: selectedOptionInterface | boolean
	option: EventOptionInterface
}>()

const emit = defineEmits(["update:modelValue"])

const selected = reactive<{ [key: number]: boolean }>({})

watch(selected, () => {
	if (isCheckboxOption.value) {
		emitSelectedAsOptionValues()
	}
}, {deep: true})

const isCheckboxOption = computed(() => {
	return props.option.type === "checkbox"
})

/** Validation of mandatory checkboxes **/
const messages = {
	required: 'Sie müssen mindestens eine Option auswählen.'
}
const mustValidate = computed(() => {
	return props.option.is_required && isCheckboxOption.value
})

const oneMustBeTrue = (value: { [key: number]: boolean }) => {
	return Object.values(value).some((v: boolean) => v === true)
}

const validation = useVuelidate(mustValidate.value ? {
	selected: {oneMustBeTrue: helpers.withMessage(messages.required, oneMustBeTrue)}
} : {}, {selected})

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const validate = () => {
	validation.value.$validate()
	return validation.value.$error
}

const errors = computed(() => {
	return validation.value.$errors
})

const getFieldError = (property: string) => {
	if (!errors.value || !Array.isArray(errors.value)) {
		return null
	} else {
		return useFieldError(property, errors.value)
	}
}

defineExpose({
	validate
})

/** END Validation of mandatory checkboxes **/


const selectRadio = (value: number) => {
	for (let key in selected) {
		selected[key] = false
	}
	selected[value] = true
	emitSelectedAsOptionValues()
}

const emitSelectedAsOptionValues = () => {
	let hasSelected = false
	let selectedValues = {}
	for (let key in selected) {
		if (selected[key] === true) {
			hasSelected = true
			selectedValues[key] = useEventOptionValue(parseInt(key), props.option)
		}
	}
	emit("update:modelValue", hasSelected ? selectedValues : false)
}
</script>
