<template>
	<div
			class="bg-white border border-secondary rounded mb-2 overflow-hidden shadow-md"
	>
		<div>
			<div class="px-4 py-2">
				<div class="mb-2">
					<h5 class="font-semibold mb-2">{{ value.event_name }}</h5>
					<Eventdate v-if="value.eventdate" :value="value.eventdate" class="mb-2"></Eventdate>
					<Student :value="value.student" class="mb-2"></Student>
					<div v-if="value.booked_options">
						<Option
								v-for="option in value.booked_options"
								:key="option.id"
								:value="option"
						></Option>
					</div>
					<Price :value="value.price">
            <span v-if="useTaxInfoString(value)" class="text-xs font-normal">
              {{ useTaxInfoString(value) }}
            </span>
					</Price>
				</div>
			</div>
			<div class="flex divide-white divide-x">
				<a
						:href="value.event.url"
						class="flex-grow flex bg-secondary text-white px-4 py-2 hover:text-white no-underline"
				>
					<div class="pr-2">
						<svg
								class="relative bottom-0.5 w-5 h-5 inline-block"
								fill="none"
								stroke="currentColor"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
						>
							<path
									d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
							/>
						</svg>
					</div>
					<div>TeilnehmerIn hinzufügen</div>
				</a>
				<button
						class="bg-secondary text-white px-4 py-2 hover:text-white no-underline text-center"
						title="Teilnahme löschen"
						@click.stop.prevent="deleteTicket(value.id)"
				>
					<svg
							class="h-5 w-5"
							fill="none"
							stroke="currentColor"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
					>
						<path
								d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
						/>
					</svg>
				</button>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
import Option from "./ticket/Option.vue"
import Student from "./ticket/Student.vue"
import Eventdate from "./ticket/Eventdate.vue"
import Price from "../../ui/Price.vue"
import {useTaxInfoString} from "../cart/composables/taxinfo"
import {BookedTicketInterface} from "@/js/interfaces/ticket"
import {inject} from "vue"
import {ToastPluginApi} from "vue-toast-notification"
import {useStore} from "@/js/store/store"

const store = useStore()

defineProps<{
	value: BookedTicketInterface
}>()

const toast = inject<ToastPluginApi>("toast")!

const deleteTicket = async (id: number) => {
	let waitToast = toast.info("Die Teilnahme wird aus der Buchung gelöscht. Bitte warten...", {
		duration: 0
	})
	try {
		await store.dispatch("deleteTicket", id)
		toast.success("Die Teilnahme wurde gelöscht.")
		if (store.getters["cartEmpty"]) {
			window.location.href = store.getters["cartConfig"].empty_cart_url
		}
	} catch (e) {
		toast.error("Die Teilnahme konnte nicht gelöscht werden.")
	} finally {
		waitToast.dismiss()
	}
}
</script>
