export const useCaptcha = () => {
    const solveChallenge = async (challenge: string, difficulty: number) => {
        let response = 0
        let hash = ''
        do {
            const buffer = new TextEncoder().encode(challenge + response)
            const digest = await crypto.subtle.digest('SHA-256', buffer)
            hash = Array.from(new Uint8Array(digest)).map(b => b.toString(16).padStart(2, '0')).join('')
            response++
        } while (hash.substring(0, difficulty) !== '0'.repeat(difficulty))
        return response - 1
    }
    return {solveChallenge}
}
