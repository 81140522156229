<template>
	<form @submit.stop.prevent="onSubmit">
		<fieldset class="z-auto">
			<div class="text-primary text-md bold uppercase mb-2" data-testid="student-title">Teilnehmer/In</div>
			<div class="flex flex-wrap mb-6">
				<div class="w-full md:w-1/2 xl:w-1/5 md:pr-2 mb-2">
					<label class="text-sm font-medium leading-5 text-gray-700">
						Herr/Frau
					</label>
					<div class="mt-1 relative shadow-sm z-10">
						<Select
								v-model="ticket.student.gender"
								:options="genderOptions"
								name="gender"
						></Select>
					</div>
					<ErrorMessagesDisplay :error="getFieldError('gender')"></ErrorMessagesDisplay>
				</div>
				<div class="w-full md:w-1/2 xl:w-1/5 xl:pr-2 mb-2">
					<label
							class="text-sm font-medium leading-5 text-gray-700"
					>
						Vorname
					</label>
					<div class="mt-1 shadow-sm relative">
						<input
								v-model="ticket.student.firstname"
								autocomplete="given-name"
								class="transition-colors form-input w-full sm:text-sm sm:leading-5 rounded-none pr-10"
								name="firstname"
								placeholder="Hans"
								type="text"
						/>
					</div>
					<ErrorMessagesDisplay :error="getFieldError('firstname')"></ErrorMessagesDisplay>
				</div>
				<div class="w-full md:w-1/2 xl:w-1/5 md:pr-2 mb-2">
					<label
							class="text-sm font-medium leading-5 text-gray-700"
					>
						Nachname
					</label>
					<div class="mt-1 relative shadow-sm">
						<input
								v-model="ticket.student.lastname"
								autocomplete="family-name"
								class="form-input w-full sm:text-sm sm:leading-5 rounded-none"
								name="lastname"
								placeholder="Muster"
								type="text"
						/>
					</div>
					<ErrorMessagesDisplay :error="getFieldError('lastname')"></ErrorMessagesDisplay>
				</div>
				<div class="w-full md:w-1/2 xl:w-1/5 xl:pr-2 mb-2">
					<label
							class="text-sm font-medium leading-5 text-gray-700"
					>
						Funktion
					</label>
					<div class="mt-1 relative shadow-sm">
						<input
								v-model="ticket.student.position"
								autocomplete="organization-title"
								class="form-input w-full sm:text-sm sm:leading-5 rounded-none"
								name="position"
								placeholder="Office Manager"
								type="text"
						/>
					</div>
					<ErrorMessagesDisplay :error="getFieldError('position')"></ErrorMessagesDisplay>
				</div>
				<div class="w-full xl:w-1/5">
					<label class="text-sm font-medium leading-5 text-gray-700">
						E-Mail
					</label>
					<div class="mt-1 relative shadow-sm">
						<input
								v-model="ticket.student.email"
								autocomplete="email"
								class="form-input w-full sm:text-sm sm:leading-5 rounded-none"
								placeholder="hans.muster@test.com"
								type="email"
						/>
						<div>
							<ErrorMessagesDisplay :error="getFieldError('email')"></ErrorMessagesDisplay>
						</div>
					</div>
				</div>
			</div>
		</fieldset>
		<fieldset v-if="addToCartHelper.eventHasEventdates() && !addToCartHelper.eventHasCustomEventdatesOption()"
		          class="mb-4">
			<CourseDates
					v-if="isCourse"
					v-model="ticket.eventdate"
					:courseEvent="config.event"
					:eventdates="config.eventdates"
					@useProposedDates="setUseProposedModuleDates($event)"
			></CourseDates>
			<EventDates
					v-else
					v-model="ticket.eventdate"
					:eventdates="config.eventdates">
			</EventDates>
			<ErrorMessagesDisplay :error="getFieldError('eventdate')"></ErrorMessagesDisplay>
		</fieldset>
		<fieldset v-if="addToCartHelper.eventHasOptions()" class="mb-4">
			<EventOptions
					ref="eventOptionComponent"
					v-model="ticket.options"
					:options="config.options"
			></EventOptions>
		</fieldset>
		<fieldset>
			<div class="flex justify-between mt-8">
				<button class="button" type="submit" data-testid="submit-button">Jetzt anmelden</button>
				<Price :value="price" class="text-right font-medium text-lg" data-testid="price">
					<span v-if="getTaxInfoString" class="text-xs font-normal">{{ getTaxInfoString() }}</span>
				</Price>
			</div>
		</fieldset>
	</form>
</template>

<script lang="ts" setup>
import Select from "../../ui/Select.vue"
import CourseDates from "./CourseDates.vue"
import EventDates from "./EventDates.vue"
import ErrorMessagesDisplay from "../../ui/ErrorMessagesDisplay.vue"
import {VueAddToCartConfigInterface} from "@/js/interfaces/cart"
import {computed, inject, reactive, ref} from "vue"
import {useTaxInfoString} from "./composables/taxinfo"
import Price from "../../ui/Price.vue"
import {TicketInterface} from "@/js/interfaces/ticket"
import {email, helpers, required} from "@vuelidate/validators"
import {useFieldError} from "@/js/composables/vuelidate"
import {useAddToCartConfigHelper} from "@/js/components/checkout/cart/composables/addToCartConfigHelper"
import {ToastPluginApi} from "vue-toast-notification"
import useVuelidate from "@vuelidate/core"
import EventOptions from "@/js/components/checkout/cart/EventOptions.vue"

const toast = inject<ToastPluginApi>('toast')!

const messages = {
	select: 'Bitte auswählen',
	required: 'Bitte füllen Sie das Feld aus',
	email: 'Bitte geben Sie eine gültige E-Mail-Adresse ein',
	eventdate: 'Bitte wählen Sie eine Durchführung'
}

const props = defineProps<{
	config: VueAddToCartConfigInterface
}>()

const emit = defineEmits(["bookTicket"])

const addToCartHelper = new useAddToCartConfigHelper(props.config)

const ticket = reactive<TicketInterface>({
	student: {
		gender: "m",
		firstname: "",
		lastname: "",
		position: "",
		email: ""
	},
	eventdate: null,
	eventdates_option: null,
	options: null,
	confirmed: true,
	module_eventdates: false
})

const ticketRules = {
	student: {
		gender: {required: helpers.withMessage(messages.select, required)},
		firstname: {required: helpers.withMessage(messages.required, required)},
		lastname: {required: helpers.withMessage(messages.required, required)},
		position: {required: helpers.withMessage(messages.required, required)},
		email: {
			required: helpers.withMessage(messages.required, required),
			email: helpers.withMessage(messages.email, email)
		}
	},
	eventdate: {required: helpers.withMessage(messages.eventdate, required)}
}

const genderOptions = [
	{id: "m", name: "Herr"},
	{id: "f", name: "Frau"},
]


const price = computed(() => {
	let eventdatePriceCorrection = 0
	let optionsPriceCorrection = 0
	const basePrice = props.config.event.price
	if (ticket.eventdate && ticket.eventdate !== 'custom') {
		eventdatePriceCorrection = addToCartHelper.getEventdatePriceCorrection(ticket.eventdate)
	}
	if (ticket.options) {
		optionsPriceCorrection = addToCartHelper.getOptionsPriceCorrection(ticket.options)
	}
	return basePrice + eventdatePriceCorrection + optionsPriceCorrection
})

const ticketValidation = useVuelidate(ticketRules, ticket)


const errors = computed(() => {
	if (!ticketValidation.value.$errors) {
		return null
	}
	return ticketValidation.value.$errors
})

const isCourse = computed(() => {
	return addToCartHelper.eventIsCourse()
})

const getTaxInfoString = () => {
	return useTaxInfoString(props.config)
}


const getFieldError = (property: string) => {
	if (!errors.value || !Array.isArray(errors.value)) {
		return null
	} else {
		return useFieldError(property, errors.value)
	}
}

const setUseProposedModuleDates = (value: boolean) => {
	ticket.module_eventdates = value
}

const eventOptionComponent = ref()

const getAllCheckboxesAreValid = () => {
	if (addToCartHelper.eventHasOptions()) {
		return eventOptionComponent.value.validateCheckboxes()
	}
	return true
}

const onSubmit = async () => {
	if (addToCartHelper.eventHasCustomEventdatesOption()) {
		ticket.eventdate = "custom"
	}
	const result = await ticketValidation.value.$validate()
	if (result && getAllCheckboxesAreValid()) {
		ticket.eventdates_option = addToCartHelper.eventHasCustomEventdatesOption()
				? "custom"
				: addToCartHelper.getEventdatesOptionId()
		emit("bookTicket", ticket)
	} else {
		toast.error("Da scheint etwas noch nicht ganz zu stimmen. Bitte prüfen Sie die Eingabefelder...")
		const addToCartElement = document.getElementById('vue-addtocart')
		if (addToCartElement) {
			addToCartElement.scrollIntoView({behavior: "smooth"})
		}
	}
}
</script>

