import {VueAddToCartConfigInterface} from "@/js/interfaces/cart"

export class useAddToCartConfigHelper {
    config: VueAddToCartConfigInterface

    constructor(config: VueAddToCartConfigInterface) {
        this.config = config
    }

    eventHasEventdates() {
        return (
            "eventdates" in this.config &&
            Array.isArray(this.config.eventdates) &&
            this.config.eventdates.length > 0)
    }

    eventHasOptions() {
        return (
            Object.prototype.hasOwnProperty.call(this.config, "options") &&
            Array.isArray(this.config.options) &&
            this.config.options.length > 0
        )
    }

    eventHasCustomEventdatesOption() {
        return this.config.event.has_custom_eventdates_option
    }

    eventIsCourse() {
        return this.config.event.is_course
    }

    getEventdates() {
        return this.config.eventdates
    }

    getOptions(includeCustomEventdateOptions = false) {
        if (!this.config.options.length) {
            return null
        }
        if (!includeCustomEventdateOptions) {
            return this.config.options.filter(option => {
                return option.is_custom_eventdates_option === false
            })
        }
        return this.config.options
    }

    getCustomEventdatesOption() {
        if (!this.getOptions()) return null
        const options = this.getOptions()!.filter(option => {
            return option.is_custom_eventdates_option === true
        })[0]
        if (!options.length) return null
        return options[0]
    }

    getCustomEventdatesOptionId() {
        if (!this.getCustomEventdatesOption()) return null
        return this.getCustomEventdatesOption().id
    }

    getEventdate(id: number) {
        const selectedEventdate = this.config.eventdates.filter((eventdate) => {
            return eventdate.id === id
        })
        if (
            Array.isArray(selectedEventdate) &&
            typeof selectedEventdate[0] == "object"
        ) {
            return selectedEventdate[0]
        }
        return null
    }

    getEventdatesOptionId() {
        if (!Object.prototype.hasOwnProperty.call(this.config, 'eventdates')) return null
        if (!this.config.eventdates.length) {
            return null
        }
        return this.config.eventdates[0].option_id
    }

    getEventdatePriceCorrection(id: number) {
        if (!id) {
            return 0
        }
        const eventdate = this.getEventdate(id)
        if (eventdate) {
            if (eventdate.price_type === "percent") {
                return (eventdate.event_price * eventdate.price_correction) / 100
            }
            return eventdate.price_correction
        }
        return 0
    }

    getOptionsPriceCorrection(options) {
        let correction = 0
        for (const id in options) {
            if (Object.prototype.hasOwnProperty.call(options, id)) {
                const option = options[id]
                if (option) {
                    for (const valueId in option) {
                        if (Object.prototype.hasOwnProperty.call(option, valueId)) {
                            const value = option[valueId]
                            if (
                                Object.prototype.hasOwnProperty.call(
                                    value,
                                    "price_correction"
                                )
                            ) {
                                if (value.price_type === "percent") {
                                    correction +=
                                        (value.event_price * value.price_correction) / 100
                                } else {
                                    correction += value.price_correction
                                }
                            }
                        }
                    }
                }
            }
        }
        return correction
    }

    getEventOption(id: number) {
        const filteredOptionConfig = this.config.options.filter((option) => {
            return option.id === id
        })
        if (
            Array.isArray(filteredOptionConfig) &&
            typeof filteredOptionConfig[0] == "object"
        ) {
            {
                return filteredOptionConfig[0]
            }
        }
        return null
    }
}
