<script setup lang="ts">
import {useTracking} from "@/js/composables/plausible"
import {onMounted} from "vue"

const tracking = useTracking()

const initTracking = () => {
	document.addEventListener('click', function (event) {
		if (!event || !event.target) {
			return
		}
		const trackingElement = (event.target as Element).closest('[data-tracking]')
		if (trackingElement) {
			const trackingValue = trackingElement.getAttribute('data-tracking')
			if (!trackingValue) {
				return
			}
			tracking.trackAction('Element', 'Click', trackingValue)
		}
	})
}
onMounted(() => {
	initTracking()
})
</script>
<template>
	<div></div>
</template>
