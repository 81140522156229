<template>
	<Listbox v-model="selected" as="div" class="w-full">
		<div class="mt-1 relative w-full">
			<ListboxButton
					class="input-box bg-white relative w-full border border-defaultborder shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary">
				<span class="tw-block truncate">{{ selected?.label }}</span>
				<span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <SelectorIcon aria-hidden="true" class="h-5 w-5 text-gray-400"/>
        </span>
			</ListboxButton>
			<transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100"
			            leave-to-class="opacity-0">
				<ListboxOptions
						class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
					<ListboxOption v-for="country in countries" :key="country.value" v-slot="{ active, selected }"
					               :country="country.value"
					               :value="country" as="template">
						<li :class="[active ? 'text-white bg-secondary' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9']">
              <span :class="[selected ? 'font-semibold' : 'font-normal', 'tw-block truncate']">
                {{ country.label }}
              </span>

							<span v-if="selected"
							      :class="[active ? 'text-white' : 'text-secondary', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                <CheckIcon aria-hidden="true" class="h-5 w-5"/>
              </span>
						</li>
					</ListboxOption>
				</ListboxOptions>
			</transition>
		</div>
	</Listbox>
</template>

<script lang="ts" setup>
import {computed, onMounted, ref} from 'vue'
import {Listbox, ListboxButton, ListboxOption, ListboxOptions,} from '@headlessui/vue'
import {CheckIcon, SelectorIcon} from '@heroicons/vue/solid'

import {CountryValueInterface} from "@/js/interfaces/allowed_countries"
import {getAllowedCountries} from "@/js/api/sp"

const props = defineProps<{ modelValue?: string }>()

const emit = defineEmits(["update:modelValue"])

const sendInput = (selectedCountry: string | undefined) => {
	emit("update:modelValue", selectedCountry)
}

const countries = ref<CountryValueInterface[]>([])

const selected = computed({
	get(): CountryValueInterface | undefined {
		if (!countries.value.length) return undefined
		const selectedCountry = countries.value.find((country) => {
			return country.value === props.modelValue
		})
		if (selectedCountry) {
			return selectedCountry
		}
		return countries.value.find((country) => {
			return country.value === "CH"
		})
	},
	set(value) {
		sendInput(value?.value)
	}
})

const loadCountries = async () => {
	countries.value = await getAllowedCountries()
}

onMounted(() => {
	loadCountries()
})
</script>
