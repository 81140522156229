<template>
	<div>
		<div v-if="cart" class="flex flex-col space-y-2">
			<div v-if="!isEmpty" class="-mb-4">
				<transition-group name="fade">
					<Ticket
							v-for="ticket in cart.tickets"
							:key="ticket.id"
							:form-key="cart.config.form_key"
							:value="ticket"
					></Ticket>
				</transition-group>
			</div>
			<div v-else>Bisher haben Sie keine Buchungen im Warenkorb</div>
		</div>
		<Loading v-else></Loading>
	</div>
</template>

<script lang="ts" setup>
import Loading from "../../ui/Loading.vue"
import Ticket from "../summary/Ticket.vue"

import {useStore} from "@/js/store/store"
import {CartStoreInterface} from "@/js/interfaces/vuex"
import {computed} from "vue"

const store = useStore()

const cart = computed((): CartStoreInterface => {
	return store.getters["cart"]
})

const isEmpty = computed((): boolean => {
	return store.getters["cartEmpty"]
})
</script>

<!--suppress CssUnusedSymbol -->
<style scoped>
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
	opacity: 0;
}
</style>
