<template>
	<div class="card relative shadow-md">
		<div v-if="position" class="absolute w-6 -left-2 -top-2 text-primary">
			<svg class="inline-block" viewBox="0 0 100 100">
				<circle
						class="fill-current shadow-md"
						cx="50%"
						cy="50%"
						fill="fill-current"
						r="50%"
				/>
				<text
						dy=".3em"
						fill="white"
						font-family="Arial"
						font-size="80px"
						text-anchor="middle"
						x="50%"
						y="50%"
				>
					{{ position }}
				</text>
			</svg>
		</div>

		<div class="card-header">
			{{ module.title }}
		</div>
		<div class="card-body text-sm white">
			<div class="border-b border-defaultborder">
				<p v-if="module.module_description">
					{{ module.module_description }}
				</p>
				<p>
					<a :href="module.url" rel="noopener noreferrer" target="_blank">
						Zum Modul
					</a>
				</p>
			</div>
			<div class="mt-4">
				<p v-if="!hasEventdates">Termine folgen.</p>
				<template v-else>
					<p>Nächste Durchführungen:</p>
					<ul>
						<li v-for="eventdate in module.eventdates" :key="eventdate.id">
							{{ eventdate.title }}
						</li>
					</ul>
				</template>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
import {computed} from "vue"
import {CourseModuleInterface} from "@/js/interfaces/course"

const props = defineProps<{
	module: CourseModuleInterface
}>()

const position = computed((): null | number => {
	if (props.module.module_position === 0) {
		return null
	}
	return props.module.module_position
})

const hasEventdates = computed((): boolean => {
	if (!Array.isArray(props.module.eventdates)) return false
	return props.module.eventdates.length !== 0
})
</script>
