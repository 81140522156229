<template>
  <span v-if="value.price_correction">
    <template v-if="correction < 0">
      <Price
		      :value="correction"
		      class="text-accent-green font-semibold"
      ></Price>
    </template>
    <template v-else>
      <Price
		      :value="correction"
		      class="text-accent-orange font-semibold"
      ></Price>
    </template>
  </span>
</template>

<script lang="ts" setup>
import Price from "./Price.vue"
import {computed} from "vue"
import {EventOptionValueInterface} from "@/js/interfaces/option"
import {EventdateInterface} from "@/js/interfaces/eventdate"

const props = defineProps<{
	value: EventOptionValueInterface | EventdateInterface
}>()

const correction = computed((): number => {
	if (props.value.price_type === "percent") {
		return (props.value.event_price * props.value.price_correction) / 100
	} else return props.value.price_correction
})
</script>
