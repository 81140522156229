<script setup lang="ts">
import {useTracking} from "@/js/composables/plausible"
import {onMounted} from "vue"

const tracking = useTracking()

const trackPageview = () => {
	tracking.pageView()
}
onMounted(() => {
	trackPageview()
})
</script>
<template>
	<div></div>
</template>
