<template>
	<div class="bg-secondary-light shadow-md border border-secondary mb-6">
		<div class="p-4 bg-secondary text-white text-lg bold uppercase">
			Automatische Termin-Benachrichtigung
		</div>
		<div class="p-4">
			<p class="mb-4">
				Jetzt hier Abonnieren und automatisch eine E-Mail bekommen, wenn für die
				Veranstaltung «{{ event.title }}» neue Termine bekannt werden
				<sup>*</sup>
			</p>
			<SubscriptionForm
					v-model="subscriber"
					:errors="errors"
					:event="event"
			></SubscriptionForm>
			<div class="text-sm mb-4">
				<sup>*</sup>
				Sie können die Benachrichtigung in jeder Benachrichtigungs-E-Mail wieder
				deaktivieren.
			</div>
			<div>
				<div class="link button inline-block" @click.stop="submit">
					Abonnieren
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
import SubscriptionForm from "./SubscriptionForm.vue"
import {CartEventInterface} from "@/js/interfaces/event"
import {subscribeToNotification} from "@/js/api/sp"
import axios from "axios"
import {email, helpers, required} from "@vuelidate/validators"
import useVuelidate from "@vuelidate/core"
import {computed, inject, ref} from "vue"
import {ToastPluginApi} from "vue-toast-notification"
import {ReissueSubscriberInterface} from "@/js/interfaces/reissue_notification"

const toast = inject<ToastPluginApi>('toast')!

const props = defineProps<{
	event: CartEventInterface
	submitUrl: string
}>()


const messages = {
	required: 'Dies ist ein Pflichtfeld',
	email: 'Bitte geben Sie eine gültige E-Mail-Adresse ein'
}

const subscriber = ref<ReissueSubscriberInterface>({
	event_id: props.event.id,
	firstname: null,
	lastname: null,
	position: null,
	email: null,
})


const subscriberValidationRules = {
	firstname: {
		required: helpers.withMessage(messages.required, required),
	},
	lastname: {
		required: helpers.withMessage(messages.required, required),
	},
	position: {
		required: helpers.withMessage(messages.required, required),
	},
	email: {
		required: helpers.withMessage(messages.required, required),
		email: helpers.withMessage(messages.email, email),
	},
}

const subscriberValidation = useVuelidate(subscriberValidationRules, subscriber)

const errors = computed(() => {
	return subscriberValidation.value.$errors
})

const submit = async () => {
	const result = await subscriberValidation.value.$validate()
	if (result) {
		try {
			const response = await subscribeToNotification(subscriber.value, props.submitUrl)
			toast.success(response)
		} catch (err) {
			if (axios.isAxiosError(err) && err.response) {
				toast.error(err.response.data)
			}
		}
	} else {
		toast.error(
				"Bitte prüfen Sie die Feldeingaben...da stimmt etwas noch nicht ganz."
		)
	}
}
</script>
