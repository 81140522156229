<template>
	<div class="flex flex-wrap mb-6 w-full">
		<div class="w-full md:w-1/2 md:pr-2 xl:w-1/4 xl:pr-2 mb-2">
			<label
					class="text-sm font-medium leading-5 text-gray-700"
					for="asker_name"
			>
				Name
			</label>
			<div class="mt-1 shadow-sm relative">
				<input
						id="asker_name"
						v-model="questionForm.asker_name"
						:class="
            getFieldError('asker_name')
              ? 'border-red-500'
              : ''
          "
						class="transition-colors form-input w-full sm:text-sm sm:leading-5 rounded-none pr-10"
						placeholder="Hans Muster"
						type="text"
				/>
				<ErrorExclamationMark
						v-if="getFieldError('asker_name')"
				></ErrorExclamationMark>
			</div>
			<ErrorMessagesDisplay
					:error="getFieldError('asker_name')"
			></ErrorMessagesDisplay>
		</div>
		<div class="w-full md:w-1/2 xl:w-1/4 xl:pr-2 mb-2">
			<label
					class="text-sm font-medium leading-5 text-gray-700"
					for="asker_email"
			>
				E-Mail-Adresse
			</label>
			<div class="mt-1 relative shadow-sm">
				<input
						id="asker_email"
						v-model="questionForm.asker_email"
						:class="
            getFieldError('asker_email')
              ? 'border-red-500'
              : ''
          "
						class="form-input w-full sm:text-sm sm:leading-5 rounded-none"
						placeholder="hans.muster@firma.ch"
						type="text"
				/>
				<ErrorExclamationMark
						v-if="getFieldError('asker_email')"
				></ErrorExclamationMark>
			</div>
			<ErrorMessagesDisplay
					:error="
          getFieldError('asker_email')
        "
			></ErrorMessagesDisplay>
		</div>
		<div class="w-full md:w-1/2 xl:w-1/4 md:pr-2 mb-2">
			<label
					class="text-sm font-medium leading-5 text-gray-700"
					for="asker_phone"
			>
				Telefonnummer
			</label>
			<div class="mt-1 relative shadow-sm">
				<input
						id="asker_phone"
						v-model="questionForm.asker_phone"
						class="form-input w-full sm:text-sm sm:leading-5 rounded-none"
						placeholder="++41 79 999 22 33"
						type="text"
				/>
			</div>
		</div>
		<div class="w-full md:w-1/2 xl:w-1/4 mb-2">
			<label
					class="text-sm font-medium leading-5 text-gray-700"
					for="asker_contact_preference"
			>
				Ich wünsche Antwort per
			</label>
			<div class="mt-1 relative shadow-sm">
				<Select
						id="asker_contact_preference"
						v-model="questionForm.asker_contact_preference"
						:options="contactOptions"
						name="asker_contact_preference"
				></Select>
			</div>
		</div>
		<div class="w-full mb-2">
			<label class="text-sm font-medium leading-5 text-gray-700" for="question">
				Meine Frage
			</label>
			<div class="mt-1 relative shadow-sm">
        <textarea
		        id="question"
		        v-model="questionForm.question"
		        :class="
           getFieldError('question')
              ? 'border-red-500'
              : ''
          "
		        class="w-full"
		        name="question"
		        rows="6"
        ></textarea>
				<ErrorExclamationMark
						v-if="getFieldError('question')"
				></ErrorExclamationMark>
			</div>
			<ErrorMessagesDisplay
					:error="getFieldError('question')"
			></ErrorMessagesDisplay>
		</div>
	</div>
</template>

<script lang="ts" setup>
import Select from "../../ui/Select.vue"
import ErrorExclamationMark from "../../ui/ErrorExclamationMark.vue"
import ErrorMessagesDisplay from "../../ui/ErrorMessagesDisplay.vue"
import {useFieldError} from "@/js/composables/vuelidate"
import {ErrorObject} from "@vuelidate/core"
import {QuestionFormInterface} from "@/js/interfaces/q_and_a"
import {computed} from "vue"

const props = withDefaults(defineProps<{
	modelValue: QuestionFormInterface,
	errors?: ErrorObject[]
}>(), {
	errors: () => {
		return []
	}
})

const contactOptions = [
	{
		id: "email",
		name: "E-Mail",
	},
	{
		id: "phone",
		name: "Telefon",
	},
]

const emit = defineEmits(["update:modelValue"])

const questionForm = computed({
	get() {
		return props.modelValue
	},
	set(questionForm) {
		emit("update:modelValue", questionForm)
	},
})

const getFieldError = (propertyName: string) => {
	return useFieldError(propertyName, props.errors)
}
</script>
