<template>
	<div class="mb-8">
		<div
				v-for="term in srcTerms"
				:key="term.id"
				class="flex flex-row justify-start"
		>
			<div class="flex flex-col justify-start pt-1">
				<SimpleCheckbox
						:value="terms.approved[term.id]"
						class="mr-4"
						@input="updateApproval(term.id, $event)"
				></SimpleCheckbox>
			</div>
			<UiModal>
				<template v-slot:opener>
					<span class="cursor-pointer">{{ term.agreement_text }}</span>
				</template>
				<template v-slot:title>{{ term.title }}</template>
				<div class="std">
					<div v-html="term.body"></div>
				</div>
			</UiModal>
		</div>
		<ErrorMessagesDisplay :error="error"></ErrorMessagesDisplay>
	</div>
</template>

<script lang="ts" setup>
import UiModal from "../ui/UiModal.vue"
import SimpleCheckbox from "./terms/SimpleCheckbox.vue"
import ErrorMessagesDisplay from "../ui/ErrorMessagesDisplay.vue"
import {useFieldError} from "@/js/composables/vuelidate"
import {computed, onMounted, ref, watch} from "vue"
import {useStore} from "@/js/store/store"
import {TermInterface} from "@/js/interfaces/terms"
import {ErrorObject} from "@vuelidate/core"

const store = useStore()

const props = withDefaults(defineProps<{
	modelValue?: object
	errors?: ErrorObject[]
}>(), {
	modelValue: () => {
		return {}
	},
	errors: () => {
		return []
	}
})

const emit = defineEmits(["update:modelValue"])

const srcTerms = computed<TermInterface[]>(() => {
	return store.getters["cartConfig"].terms
})

const terms = ref({
	approved: props.modelValue,
})

const error = computed(() => {
	return useFieldError('approved', props.errors)
})


watch(srcTerms, () => {
	/** must reset on change of terms (e.g. if deleting ticket) **/
	resetApproved()
}, {deep: true})

const updateApproval = (id: number, val: boolean) => {
	terms.value.approved[id] = val
	emit("update:modelValue", terms.value.approved)
}

const resetApproved = () => {
	terms.value.approved = {}
	if (srcTerms.value && Array.isArray(srcTerms.value)) {
		srcTerms.value.forEach((term) => {
			terms.value.approved[term.id] = false
		})
	}
	emit("update:modelValue", terms.value.approved)
}

onMounted(() => {
	resetApproved()
})
</script>
