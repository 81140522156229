<template>
	<div class="flex items-center border-secondary border bg-gray-100 p-4 mb-4">
		<input type="checkbox" v-model="isChecked" name="captcha" id="captcha"
		       class="form-checkbox h-3 w-3 text-blue-600 transition duration-150 ease-in-out" @click="verifyHuman">
		<label for="captcha" class="ml-4 inline-block text-sm leading-5 text-gray-900">
			Ich bin kein Roboter.
		</label>
		<div v-if="isLoading" class="text-sm flex items-baseline">
			<div class="ml-2 loader"></div>
			<div class="ml-2 animate-pulse">bitte kurz warten...</div>
		</div>
		<input type="hidden" v-model="solution" name="human-check" v-if="isChecked">
	</div>
</template>

<script setup lang="ts">
import {onMounted, ref} from 'vue'
import {useCaptcha} from "@/js/composables/captcha"
import {getCaptchaConfig} from "@/js/api/sp"
import {useToast} from "vue-toast-notification"

const toast = useToast()

const emit = defineEmits(['input'])

const challenge = ref<string>('')
const difficulty = ref<number>(4)

const solution = ref<null | number>()

const captcha = useCaptcha()
const isChecked = ref(false)
const isLoading = ref(false)

const verifyHuman = async () => {
	isLoading.value = true
	const response = await captcha.solveChallenge(challenge.value, difficulty.value)
	if (response) {
		solution.value = response
		isChecked.value = true
		emit('input', response)
	}
	isLoading.value = false
}

onMounted(async () => {
	try {
		const config = await getCaptchaConfig()
		challenge.value = config.challenge
		difficulty.value = config.difficulty
	} catch (e) {
		toast.error('Fehler beim Laden der Captcha-Konfiguration')
	}
})
</script>

<style scoped>
label {
	@apply cursor-pointer mb-0 !important;
}

input[type="checkbox"]:checked {
	transform: scale(1.5);
	transition: transform 0.3s ease-in-out;
}

.loader {
	@apply border-2 border-secondary-light border-t-2 border-t-primary rounded-full w-3 h-3 animate-spin;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
</style>
