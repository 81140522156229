<template>
	<div class="mt-2 text-sm leading-5 font-normal">
		<div @click="emit('update:modelValue', proposedModuleDateIds)">
			<div class="flex">
				<input
						:id="'option-' + eventdate.id + '-default'"
						:checked="modelValue"
						:class="
            modelValue
              ? 'border-' + twColorSelected + ' text-' + twColorSelected
              : 'border-' + twColorUnselected + ' text-' + twColorUnselected
          "
						class="form-radio h-4 w-4 transition duration-150 ease-in-out cursor-pointer"
						type="radio"
				/>
				<label
						:for="'option-' + eventdate.id + '-default'"
						class="ml-3 font-medium"
				>
					Ich nehme an den Modul-Terminen für den schnellstmöglichen Abschluss
					teil
				</label>
			</div>
			<div class="ml-12">
				<ul v-if="proposedModuleDateTexts" class="list-disc">
					<li v-for="(moduleDateText, index) in proposedModuleDateTexts" :key="index">
						{{ moduleDateText }}
					</li>
				</ul>
				<div
						v-else
						class="animate-pulse flex justify-start items-center -ml-5 py-4"
				>
					<div
							class="animate-spin rounded-full h-4 w-4 border-b-2 border-gray-900 mr-4"
					></div>
					<div>Optimale Termine werden berechnet...</div>
				</div>
			</div>
		</div>
		<div class="flex items-center mt-2" @click="emit('update:modelValue', false)">
			<input
					:id="'option-' + eventdate.id + '-unselected'"
					:checked="!modelValue"
					:class="
          !modelValue
            ? 'border-' + twColorSelected + ' text-' + twColorSelected
            : 'border-' + twColorUnselected + ' text-' + twColorUnselected
        "
					class="form-radio h-4 w-4 transition duration-150 ease-in-out cursor-pointer"
					type="radio"
			/>
			<label
					:for="'option-' + eventdate.id + '-unselected'"
					class="ml-3 font-medium"
			>
				Ich möchte gerne einen oder mehrere Folgetermine verschieben. Bitte
				kontaktieren Sie mich nach der Buchung.
			</label>
		</div>
	</div>
</template>

<script lang="ts" setup>
import {getProposedModuleDates} from '@/js/api/sp'
import {computed, onMounted, ref} from 'vue'
import {CartEventInterface} from '@/js/interfaces/event'
import {EventdateInterface} from "@/js/interfaces/eventdate"
import axios from "axios"

const props = withDefaults(defineProps<{
	eventdate: EventdateInterface,
	courseEvent: CartEventInterface,
	modelValue?: number[],
	twColorBgSelected?: string,
	twColorBgUnselected?: string,
	twColorBorderSelected?: string,
	twColorBorderUnselected?: string,
	twColorSelected?: string,
	twColorUnselected?: string,
	twColorCommentSelected?: string,
	twColorCommentUnselected?: string,
}>(), {
	twColorBgSelected: "white",
	twColorBgUnselected: "white",
	twColorBorderSelected: "blue-500",
	twColorBorderUnselected: "gray-300",
	twColorSelected: "primary",
	twColorUnselected: "gray-500",
	twColorCommentSelected: "blue-700",
	twColorCommentUnselected: "gray-500",
})

const emit = defineEmits(["update:modelValue"])

const proposedModuleDates = ref<EventdateInterface[]>([])


const proposedModuleDateTexts = computed((): string[] => {
	if (!Array.isArray(proposedModuleDates.value) || !proposedModuleDates.value.length) {
		return []
	}
	let texts: string[] = []
	proposedModuleDates.value.forEach((eventdate) => {
		let text = ""
		Object.hasOwnProperty.call(eventdate, "event")
				? (text += eventdate.title + ": " + eventdate.event.title)
				: (text +=
						"*offen*: " +
						eventdate.event_title +
						" (Sie werden kontaktiert, sobald neue Termine feststehen)")
		texts.push(text)
	})
	return texts
})

const proposedModuleDateIds = computed((): number[] => {
	if (!Array.isArray(proposedModuleDates.value) || !proposedModuleDates.value.length) {
		return []
	}
	return proposedModuleDates.value.map((module) => {
		return module.id
	})
})


const fetchProposedModuleDates = async () => {
	try {
		proposedModuleDates.value = await getProposedModuleDates(props.courseEvent.id, props.eventdate.start_date)
		emit("update:modelValue", proposedModuleDateIds.value)
	} catch (e) {
		if (axios.isAxiosError(e) && Object.prototype.hasOwnProperty.call(e, "response")) {
			console.error(e.response)
		}
	}
}

onMounted(() => {
	fetchProposedModuleDates()
})
</script>

