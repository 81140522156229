import Vue, {createApp} from "vue"
import TopCart from "./components/checkout/TopCart.vue"
import UiCard from "./components/ui/UiCard.vue"
import UiModal from "./components/ui/UiModal.vue"
import AccountNavigation from "./components/customer/AccountNavigation.vue"
import VueAddToCart from "./components/checkout/cart/VueAddToCart.vue"
import VueCheckout from "./components/checkout/Checkout.vue"
import EventReviewForm from "./components/review/EventReviewForm.vue"
import EventAlternatives from "./components/alternatives/EventAlternatives.vue"
import * as Sentry from "@sentry/vue"
import {store, key} from "./store/store"
import VueSocialSharing from "vue-social-sharing"
import ModuleList from "./components/course/ModuleList.vue"
import RegisterButton from "./components/marketing/partner/RegisterButton.vue"
import RegisterForm from "./components/marketing/partner/RegisterForm.vue"
import ReissueNotification from "./components/product/notification/ReissueNotification.vue"
import NewsletterWidget from "./components/widgets/NewsletterWidget.vue"
import SidebarNotificationLink from "./components/product/notification/SidebarNotificationLink.vue"
import SidebarAskQuestionLink from "./components/product/question/SidebarAskQuestionLink.vue"
import VeranstalterAlert from "./components/veranstalteralert/Alert.vue"
import BottomPinnedActions from "./components/ui/BottomPinnedActions.vue"
import ScrollToElementOnClick from "./components/ui/ScrollToElementOnClick.vue"
import plausible from "./plugins/plausible"
import Tracker from '@openreplay/tracker'
import trackerAssist from '@openreplay/tracker-assist'
import Cookies from 'js-cookie'
import VueToast from "vue-toast-notification"
import AssetBanner from "@/js/components/marketing/asset/AssetBanner.vue"
import DirectiveTracker from "@/js/components/tracking/DirectiveTracker.vue"
import PageTracker from "@/js/components/tracking/PageTracker.vue"
import SearchField from "@/js/components/search/SearchField.vue"
import Captcha from "@/js/components/ui/Captcha.vue"

declare global {
    interface Window {
        setLocation: (url: string) => void;
    }
}

window.setLocation = function (url) {
    window.location.href = encodeURI(url)
}

const OpenReplayProjectKey = import.meta.env.VITE_OPENREPLAY_KEY
if (OpenReplayProjectKey) {
    try {
        const tracker = new Tracker({
            projectKey: OpenReplayProjectKey,
        })
        tracker.start().catch((e) => {
            console.error(e)
        }).then(() => {
            if (tracker && import.meta.env.VITE_OPENREPLAY_ENABLE_ASSIST && import.meta.env.VITE_OPENREPLAY_ENABLE_ASSIST == 'true') {
                tracker.use(trackerAssist())
            }
        })
    } catch (e) {
        console.error(e)
    }
}

/** Font Awesome */
import {library, dom} from "@fortawesome/fontawesome-svg-core"
import {faCalendar} from "@fortawesome/free-solid-svg-icons/faCalendar"
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons/faExclamationCircle"
import {faFilePdf} from "@fortawesome/free-solid-svg-icons/faFilePdf"
import {faPhone} from "@fortawesome/free-solid-svg-icons/faPhone"
import {faEnvelope} from "@fortawesome/free-solid-svg-icons/faEnvelope"
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons/faQuestionCircle"
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome"
import {
    faFacebook,
    faTwitter,
    faPinterest,
} from "@fortawesome/free-brands-svg-icons"
import StarRatingDisplay from "@/js/components/ui/StarRatingDisplay.vue"

library.add(faExclamationCircle)
library.add(faCalendar)
library.add(faFilePdf)
library.add(faPhone)
library.add(faQuestionCircle)
library.add(faFacebook)
library.add(faTwitter)
library.add(faPinterest)
library.add(faEnvelope)
dom.watch()

const cookieApi = Cookies.withAttributes({
    sameSite: 'lax',
    path: '/',
    domain: import.meta.env.VITE_COOKIE_DOMAIN,
})

const sentryApp = createApp({})
Sentry.init({
    app: sentryApp,
    environment: import.meta.env.MODE,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    autoSessionTracking: true,
    release: import.meta.env.VITE_SENTRY_RELEASE || '0.0.0',
})

const plausibleDomain = import.meta.env.VITE_PLAUSIBLE_DOMAIN
const plausibleApiHost = import.meta.env.VITE_PLAUSIBLE_API_HOST

function addDefaultComponentsTo(app: Vue.App): Vue.App {
    const isAdmin = !!cookieApi.get('adminhtml')
    app.use(store, key)
    app.use(VueToast, {position: "top-right"})
    app.provide('toast', app.config.globalProperties.$toast)
    app.component("UiCard", UiCard)
    app.component("UiModal", UiModal)
    app.component("font-awesome-icon", FontAwesomeIcon)
    if (plausibleDomain !== undefined && !isAdmin) {
        app.use(plausible, {
            domain: plausibleDomain,
            trackLocalhost: false,
            apiHost: plausibleApiHost || 'https://plausible.io',
            enableAutoPageviews: false,
            enableAutoOutboundTracking: false,
        })
        app.provide('$plausible', app.config.globalProperties.$Plausible)
    }
    return app
}

if (document.getElementById("top-navigation")) {
    const app = createApp({})
    addDefaultComponentsTo(app)
    app.component("TopCart", TopCart)
    app.component("AccountNavigation", AccountNavigation)
    app.mount("#top-navigation")
}

if (document.getElementById("vue-contains-modals")) {
    const app = createApp({})
    addDefaultComponentsTo(app)
    app.mount("#vue-contains-modals")
}


if (document.getElementById("vue-checkout")) {
    const app = createApp({})
    app.component("VueCheckout", VueCheckout)
    addDefaultComponentsTo(app)
    app.mount("#vue-checkout")
}

if (document.getElementById("vue-addtocart")) {
    const app = createApp({})
    addDefaultComponentsTo(app)
    app.use(VueSocialSharing)
    app.component("VueAddToCart", VueAddToCart)
    app.mount("#vue-addtocart")
}

if (document.getElementById("course")) {
    const app = createApp({})
    app.component("ModuleList", ModuleList)
    addDefaultComponentsTo(app)
    app.mount("#course")
}

if (document.getElementById("partner-join")) {
    const app = createApp({})
    app.component("ModuleList", ModuleList)
    app.component("RegisterButton", RegisterButton)
    app.component("RegisterForm", RegisterForm)
    addDefaultComponentsTo(app)
    app.mount("#partner-join")
}

const elements = document.getElementsByClassName("contains-reissue-notification")
for (let i = 0; i < elements.length; i++) {
    const app = createApp({})
    app.component("ReissueNotification", ReissueNotification)
    addDefaultComponentsTo(app)
    app.mount(elements[i])
}

if (document.getElementById("contains-newsletter-widget")) {
    const app = createApp({})
    app.component("NewsletterWidget", NewsletterWidget)
    addDefaultComponentsTo(app)
    app.mount("#contains-newsletter-widget")
}

if (document.getElementById("sidebar-eventdate-notification")) {
    const app = createApp({})
    app.component("SidebarNotificationLink", SidebarNotificationLink)
    addDefaultComponentsTo(app)
    app.mount("#sidebar-eventdate-notification")
}

if (document.getElementById("sidebar-ask-question-link")) {
    const app = createApp({})
    app.component("SidebarAskQuestionLink", SidebarAskQuestionLink)
    addDefaultComponentsTo(app)
    app.mount("#sidebar-ask-question-link")
}

if (document.getElementById("veranstalteralert")) {
    const app = createApp({})
    app.component("VeranstalterAlert", VeranstalterAlert)
    addDefaultComponentsTo(app)
    app.mount("#veranstalteralert")
}

if (document.getElementById("vue-pinned-to-bottom")) {
    const app = createApp({})
    app.component("BottomPinnedActions", BottomPinnedActions)
    app.component("ScrollToElementOnClick", ScrollToElementOnClick)
    addDefaultComponentsTo(app)
    app.mount("#vue-pinned-to-bottom")
}

if (document.getElementById("review-form")) {
    const app = createApp({})
    app.component("EventReviewForm", EventReviewForm)
    addDefaultComponentsTo(app)
    app.mount("#review-form")
}
if (document.getElementById("event-alternatives")) {
    const app = createApp({})
    app.component("EventAlternatives", EventAlternatives)
    addDefaultComponentsTo(app)
    app.mount("#event-alternatives")
}

const assetBanners = document.getElementsByClassName("contains-asset-banner")
for (let i = 0; i < assetBanners.length; i++) {
    const app = createApp({})
    app.component("AssetBanner", AssetBanner)
    addDefaultComponentsTo(app)
    app.mount(assetBanners[i])
}

const starRatings = document.getElementsByClassName("contains-star-rating")
for (let i = 0; i < starRatings.length; i++) {
    const app = createApp({})
    app.component("StarRatingDisplay", StarRatingDisplay)
    // addDefaultComponentsTo(app)
    app.mount(starRatings[i])
}

if (document.getElementById("contains-tracker")) {
    const isAdmin = !!cookieApi.get('adminhtml')
    const app = createApp({})
    app.component("DirectiveTracker", DirectiveTracker)
    app.component("PageTracker", PageTracker)
    if (plausibleDomain !== undefined && !isAdmin) {
        app.use(plausible, {
            domain: plausibleDomain,
            trackLocalhost: true,
            apiHost: plausibleApiHost || 'https://plausible.io',
            enableAutoPageviews: false,
            enableAutoOutboundTracking: true,
        })
        app.provide('$plausible', app.config.globalProperties.$Plausible)
    }
    app.mount("#contains-tracker")
}

if (document.getElementById("contains-search-form")) {
    const app = createApp({})
    app.component("SearchField", SearchField)
    addDefaultComponentsTo(app)
    app.mount("#contains-search-form")
}

if (document.getElementById("contains-captcha")) {
    const app = createApp({})
    app.component("Captcha", Captcha)
    addDefaultComponentsTo(app)
    app.mount("#contains-captcha")
}

