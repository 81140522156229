<script setup lang="ts">
import {CatalogSearchConfigInterface} from "@/js/interfaces/catalogsearch"
import {SearchIcon} from '@heroicons/vue/outline'
import {RefreshIcon} from '@heroicons/vue/outline'
import {ref} from "vue"
import {getSearchResults} from "@/js/api/sp"
import {useTracking} from "@/js/composables/plausible"

const tracking = useTracking()

const props = defineProps<{
	config: CatalogSearchConfigInterface
}>()

const query = ref('')
const isLoading = ref(false)


// eslint-disable-next-line no-undef
let timer = 0 as unknown as NodeJS.Timeout

const toResults = () => {
	if (query.value.length > props.config.query.min_length) {
		tracking.trackAction('Search', 'Results', query.value, 0)
		window.location.href = `${props.config.urls.result}?q=${encodeURIComponent(query.value)}`
	}
}

const results = ref([])

const autoComplete = async () => {
	if (query.value.length < props.config.query.min_length) {
		results.value = []
		return
	}
	try {
		if (query.value.length > props.config.query.min_length) {
			clearTimeout(timer)
			timer = setTimeout(async () => {
				isLoading.value = true
				const sanitizedQuery = encodeURIComponent(query.value)
				results.value = await getSearchResults(sanitizedQuery, props.config)
				tracking.trackAction('Search', 'Autocomplete', query.value, 0)
				isLoading.value = false
			}, 600)
		}
	} catch (e) {
		isLoading.value = false
		console.error(e)
	}
}
</script>

<template>
	<div class="search-container">
		<input id="search"
		       type="search"
		       v-model="query"
		       class="input-text"
		       :maxlength="config.query.max_length"
		       @keyup.enter="toResults"
		       @input="autoComplete"
		       autocomplete="off"
		       placeholder="Veranstaltung suchen..."/>
		<RefreshIcon v-if="isLoading" class="refresh-icon spinning"/>
		<SearchIcon v-else class="search-icon" @click="toResults"/>
	</div>
	<div id="search_autocomplete" class="search-autocomplete" v-html="results"></div>
</template>

<style scoped>
@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.search-container {
	position: relative;
}

.search-icon {
	position: absolute;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
	width: 20px;
	height: 20px;
}

.refresh-icon {
	position: absolute;
	right: 10px;
	top: calc(50% - 10px); /* Adjust this value as needed */
	width: 20px;
	height: 20px;
}

.spinning {
	animation: spin 2s linear infinite;
}
</style>
