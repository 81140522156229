<template>
	<fieldset>
		<div>
			<div class="vue-checkout-fieldrow">
				<label for="ba-company">Firma</label>
				<div class="mt-1 relative shadow-sm">
					<input
							id="ba-company"
							v-model="billing.company"
							placeholder="Muster AG"
							type="text"
					/>
				</div>
			</div>
			<div class="vue-checkout-fieldrow">
				<label for="ba-firstname">Vorname</label>
				<div class="mt-1 relative shadow-sm">
					<input
							id="ba-firstname"
							v-model="billing.firstname"
							placeholder="Hans"
							type="text"
					/>
					<ErrorMessagesDisplay :error="useFieldError('firstname', props.errors)"></ErrorMessagesDisplay>
				</div>
			</div>
			<div class="vue-checkout-fieldrow">
				<label for="ba-lastname">Nachname</label>
				<div class="mt-1 relative shadow-sm">
					<input
							id="ba-lastname"
							v-model="billing.lastname"
							placeholder="Muster"
							type="text"
					/>
				</div>
				<ErrorMessagesDisplay :error="useFieldError('lastname', props.errors)"></ErrorMessagesDisplay>
			</div>
			<div class="vue-checkout-fieldrow">
				<label>Strasse</label>
				<div class="mt-1 relative shadow-sm">
					<div class="flex w-full">
						<AddressInput v-model="billing.street"></AddressInput>
					</div>
				</div>
				<ErrorMessagesDisplay :error="useFieldError('street', props.errors)"></ErrorMessagesDisplay>
			</div>
			<div class="vue-checkout-fieldrow flex space-x-2">
				<div class="w-1/5">
					<label for="ba-postcode">PLZ</label>
					<div class="mt-1 relative shadow-sm">
						<input
								id="ba-postcode"
								v-model="billing.postcode"
								placeholder="6004"
								type="text"
						/>
					</div>
					<ErrorMessagesDisplay :error="useFieldError('postcode', props.errors)"></ErrorMessagesDisplay>
				</div>
				<div class="w-4/5">
					<label for="ba-city">Ort</label>
					<div class="mt-1 relative shadow-sm">
						<input
								id="ba-city"
								v-model="billing.city"
								placeholder="Musterhausen"
								type="text"
						/>
					</div>
					<ErrorMessagesDisplay :error="useFieldError('city', props.errors)"></ErrorMessagesDisplay>
				</div>
			</div>
			<div class="vue-checkout-fieldrow">
				<label>Land</label>
				<div class="mt-1 relative shadow-sm">
					<div class="flex w-full">
						<CountrySelect
								id="ba-country"
								v-model="billing.country_id"
						></CountrySelect>
					</div>
					<ErrorMessagesDisplay :error="useFieldError('country_id', props.errors)"></ErrorMessagesDisplay>
				</div>
			</div>
			<div class="vue-checkout-fieldrow">
				<label for="ba-email">E-Mail</label>
				<div class="mt-1 relative shadow-sm">
					<input
							id="ba-email"
							v-model="billing.email"
							placeholder="hans.muster@test.com"
							type="email"
					/>
				</div>
				<ErrorMessagesDisplay :error="useFieldError('email', props.errors)"></ErrorMessagesDisplay>
			</div>
			<div class="vue-checkout-fieldrow">
				<label>Telefon</label>
				<div class="mt-1 relative shadow-sm">
					<input
							v-model="billing.telephone"
							name="telephone"
							placeholder="++41 76 999 99 99"
							type="text"
					/>
				</div>
				<ErrorMessagesDisplay :error="useFieldError('telephone', props.errors)"></ErrorMessagesDisplay>
			</div>
		</div>
	</fieldset>
</template>

<script lang="ts" setup>
import AddressInput from "./AddressInput.vue"
import CountrySelect from "./CountrySelect.vue"
import ErrorMessagesDisplay from "../ui/ErrorMessagesDisplay.vue"
import {computed} from "vue"
import {CustomerAddressInterface} from "@/js/interfaces/address"
import {useFieldError} from "@/js/composables/vuelidate"
import {ErrorObject} from "@vuelidate/core"

const props = defineProps<{
	modelValue: CustomerAddressInterface,
	errors: ErrorObject[]
}>()

const emit = defineEmits(["update:modelValue"])

const billing = computed<CustomerAddressInterface>({
	get() {
		return props.modelValue
	},
	set(value) {
		emit("update:modelValue", value)
	}
})
</script>

<style scoped>
.vue-checkout-fieldrow {
	@apply mb-4;
}

label {
	@apply font-medium leading-5 text-gray-700;
}

input {
	@apply w-full;
}
</style>
