<template>
	<form @submit.prevent="submit">
		<div class="mb-4">
			<h1>Anmeldung abschliessen</h1>
			<div
					v-if="config.checkout_description && config.checkout_description !== ''"
					class="mb-4"
			>
				{{ config.checkout_description }}
			</div>
		</div>
		<div class="md:grid grid-cols-3 gap-8">
			<div id="ba">
				<h4 class="mb-4">Rechnungsadresse</h4>
				<CustomerAddressSelector
						ref="addressSelector"
						v-model="selectedCustomerAddress"
						@update:modelValue="fillInStudentWithId"
				></CustomerAddressSelector>
				<BillingAddressForm v-model="form.billing" :errors="formValidation.$errors"></BillingAddressForm>
			</div>
			<div>
				<div v-if="config.custom_order_attributes && config.custom_order_attributes.length">
					<h4 class="mb-4">Zusätzliche Angaben</h4>
					<CustomOptions :customOrderAttributes="config.custom_order_attributes"></CustomOptions>
				</div>
				<h4 class="mb-4">Ihre Buchung</h4>
				<Summary></Summary>
			</div>
			<div>
				<div class="mb-8">
					<h4 class="mb-4">Ihr Kommentar</h4>
					<textarea
							v-model="form.comment"
							class="w-full border-defaultborder"
							placeholder="Ihre Nachricht an uns."
							rows="5"
					></textarea>
					<p class="text-xs">
						Möchten Sie uns zur Buchung noch etwas mitteilen? Etwa eine
						Referenznummer, die auf die Rechnung sollte? Oder möchten Sie eine
						abweichende Rechnungsadresse eingeben? Hier ist der richtige Ort,
						uns dies mitzuteilen.
					</p>
				</div>
				<h4 class="mb-4">Buchungsbedingungen</h4>
				<TermsApproval v-model="termsApproved.approved" :errors="termValidation.$errors"></TermsApproval>
				<button :disabled="submitting" class="button button-orange uppercase text-xl" type="submit">
					Anmeldung abschliessen
				</button>
			</div>
		</div>
	</form>
</template>

<script lang="ts" setup>
import {computed, inject, nextTick, onMounted, reactive, ref, watch} from 'vue'
import Summary from '@/js/components/checkout/Summary.vue'
import CustomerAddressSelector from '@/js/components/checkout/customer/CustomerAddressSelector.vue'
import BillingAddressForm from '@/js/components/checkout/BillingAddressForm.vue'
import {email, helpers, minLength, required} from '@vuelidate/validators'
import {submitOrder} from "@/js/api/sp"
import TermsApproval from "@/js/components/checkout/TermsApproval.vue"
import * as Sentry from "@sentry/vue"
import {CheckoutFormInterface} from "@/js/interfaces/checkout"
import {CartConfigInterface, CartStoreInterface, CustomerStoreInterface} from "@/js/interfaces/vuex"
import {ToastPluginApi} from "vue-toast-notification"
import useVuelidate from "@vuelidate/core"
import {useTracking} from "@/js/composables/plausible"
import {useStore} from "@/js/store/store"
import CustomOptions from "@/js/components/checkout/CustomOptions.vue"

const toast = inject<ToastPluginApi>('toast')!

const tracking = useTracking()

const store = useStore()


const messages = {
	required: 'Dies ist ein Pflichtfeld',
	email: 'Bitte geben Sie eine gültige E-Mail-Adresse ein',
	terms: 'Sie müssen den Buchungsbedingungen zustimmen'
}

const termsMustBeAccepted = (terms) => {
	for (const term in terms) {
		if (terms[term] === false) {
			return false
		}
	}
	return true
}

const billingInitialized = ref<boolean>(false)
const selectedCustomerAddress = ref(null)
const form = reactive<CheckoutFormInterface>({
	billing: {
		company: null,
		firstname: null,
		lastname: null,
		street: [],
		postcode: null,
		city: null,
		country_id: "CH",
		telephone: null,
		email: null
	},
	address_changed: false,
	comment: null,
})

const formValidationRules = {
	billing: {
		firstname: {required: helpers.withMessage(messages.required, required)},
		lastname: {required: helpers.withMessage(messages.required, required)},
		street: {
			required: helpers.withMessage(messages.required, required),
			minLength: helpers.withMessage(messages.required, minLength(1))
		},
		postcode: {required: helpers.withMessage(messages.required, required)},
		city: {required: helpers.withMessage(messages.required, required)},
		country_id: {required: helpers.withMessage(messages.required, required)},
		telephone: {required: helpers.withMessage(messages.required, required)},
		email: {
			required: helpers.withMessage(messages.required, required),
			email: helpers.withMessage(messages.email, email)
		}
	},
}

const termValidationRules = {
	approved: {termsMustBeAccepted: helpers.withMessage(messages.terms, termsMustBeAccepted)}
}

interface ApprovedTermsInterface {
	approved: {
		[key: number]: boolean
	}
}

const termsApproved = reactive<ApprovedTermsInterface>({
	approved: {}
})

const isNewAddress = ref<boolean>(true)

const submitting = ref<boolean>(false)

const config = computed((): CartConfigInterface => store.getters["cartConfig"])
const customer = computed((): CustomerStoreInterface => store.getters["customer"])
const cart = computed((): CartStoreInterface => {
	return store.getters["cart"]
})

const grandTotal = computed(() => {
	let t = 0
	for (const total of cart.value.totals) {
		if (total.code === "grand_total") {
			t = total.value
		}
	}
	return t
})

const termValidation = useVuelidate(termValidationRules, termsApproved)
const formValidation = useVuelidate(formValidationRules, form)
const billingAddress = computed(() => {
	return form.billing
})
watch(billingAddress, () => {
	if (billingInitialized.value) {
		form.address_changed = true
	}
}, {deep: true})

watch(billingAddress, () => {
			store.dispatch("setBillingAddress", billingAddress.value)
		}, {deep: true}
)

const fillInStudentWithId = (id) => {
	const addresses = store.getters["customerAddresses"].all.filter(
			(customerAddress) => {
				return customerAddress.id === id
			}
	)
	if (addresses.length) {
		setBillingAddress(addresses[0])
		if (!isNewAddress.value) {
			hideAddressSelector()
		} else {
			isNewAddress.value = false
		}
	}
}

const setBillingAddress = (address) => {
	billingInitialized.value = false
	form.address_changed = false
	if (address) {
		form.billing = {
			company: address.company,
			firstname: address.firstname,
			lastname: address.lastname,
			street: address.street,
			postcode: address.postcode,
			city: address.city,
			country_id: address.country_id,
			telephone: address.telephone,
			email: customer.value.email,
		}
	} else {
		form.billing = {
			company: null,
			firstname: null,
			lastname: null,
			street: [],
			postcode: null,
			city: null,
			country_id: "CH",
			telephone: null,
			email: customer.value.email,
		}
	}
	nextTick(() => {
		billingInitialized.value = true
	})
}


const submit = async () => {
	submitting.value = true
	const formIsValid = await formValidation.value.$validate()
	const termsAreValid = await termValidation.value.$validate()
	if (formIsValid && termsAreValid) {
		try {
			toast.info(
					"Ihre Buchung wird jetzt übermittelt. Bitte warten Sie einen kleinen Moment..."
			)
			await submitOrder(form, config.value.submit_url)
			tracking.cart("Order", "Order has been placed", grandTotal.value)
			window.location.href = "/checkout/onepage/success"
		} catch (e) {
			toast.error(
					"Bei der Übermittlung Ihrer Buchung ist ein Fehler aufgetreten. Probieren Sie es bitte noch einmal oder " +
					"wenden Sie sich per Telefon oder Kontaktformular an uns."
			)
			Sentry.captureException(e)
			submitting.value = false
		} finally {
			submitting.value = false
		}
	} else {
		toast.error("Da scheint etwas noch nicht ganz zu stimmen. Bitte prüfen Sie die Eingabefelder...")
		const billingAddressElement = document.getElementById('ba')!
		billingAddressElement.scrollTo({behavior: "smooth"})
		submitting.value = false
	}
}

const hideAddressSelector = () => {
	const addressSelector = document.getElementById("address-selector")
	if (addressSelector) {
		addressSelector.classList.add("hidden")
	}
}

onMounted(() => {
	form.billing = store.getters["billingAddress"]
})
</script>
