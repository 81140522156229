<template>
	<input v-model="checked" type="checkbox"/>
</template>

<script lang="ts" setup>

import {computed} from "vue"

const props = withDefaults(defineProps<{
	value: boolean
}>(), {
	value: false
})

const emit = defineEmits(["input"])

const checked = computed({
	get: () => {
		return props.value
	},
	set: (value) => {
		emit("input", value)
	}
})
</script>
