<template>
	<star-rating :model-value="modelValue" active-color-tw-code="yellow-500" hover-color-tw-code="yellow-500"
	             inactive-color-tw-code="yellow-300"
	             @update:modelValue="emitValue"></star-rating>
</template>

<script lang="ts" setup>
import StarRating from "@/js/components/ui/StarRating.vue"

withDefaults(defineProps<{
	modelValue?: number
}>(), {
	modelValue: 0,
})

const emit = defineEmits(["update:modelValue"])

const emitValue = (value: number) => {
	emit("update:modelValue", value)
}
</script>
