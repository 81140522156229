<template>
	<div class="mb-4 bg-gray-200 p-4">
		<div v-if="cart && !isEmpty" class="flex flex-col space-y-2">
			<transition-group name="fade">
				<Ticket
						v-for="ticket in cart.tickets"
						:key="ticket.id"
						:value="ticket"
				></Ticket>
			</transition-group>
			<Totals :value="cart.totals"></Totals>
		</div>
		<Loading v-else></Loading>
	</div>
</template>

<script lang="ts" setup>
import Ticket from "./summary/Ticket.vue"
import Totals from "./summary/Totals.vue"
import Loading from "../ui/Loading.vue"
import {useStore} from "@/js/store/store"
import {computed} from "vue"
import {CartStoreInterface} from "@/js/interfaces/vuex"

const store = useStore()

const cart = computed((): CartStoreInterface => {
	return store.getters["cart"]
})

const isEmpty = computed((): boolean => {
	return store.getters["cartEmpty"]
})
</script>

<!--suppress CssUnusedSymbol -->
<style scoped>
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
	opacity: 0;
}
</style>
