import Plausible from 'plausible-tracker'

export default {
    install: (app, options) => {
        const instance = Plausible(options)
        instance.enableAutoOutboundTracking()
        // instance.enableAutoPageviews()
        app.config.globalProperties.$Plausible = instance
    },
}
