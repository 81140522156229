<template>
	<div>
		<div v-for="attribute in customOrderAttributes" :key="attribute.id">
			<label :for="attribute.key" class="main-label">{{ attribute.label }}</label>
			<input v-if="attribute.type === 'text'" type="text" :id="attribute.key" v-model="form[attribute.key]">
			<textarea v-else-if="attribute.type === 'textarea'" :id="attribute.key" v-model="form[attribute.key]"></textarea>
			<div v-else-if="attribute.type === 'radio'" v-for="(value, key) in attribute.option_values"
			     :key="'radio-' + key">
				<input type="radio" :value="key" v-model="form[attribute.key]" class="mr-2"/>
				<label :for="key">{{ value }}</label>
			</div>
			<div v-else-if="attribute.type === 'checkbox'" v-for="(value, key) in attribute.option_values"
			     :key="'checkbox-' + key">
				<input type="checkbox" :value="key" v-model="form[attribute.key]" class="mr-2"/>
				<label :for="key">{{ value }}</label>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import {reactive} from 'vue'
import {CustomOrderAttributeInterface} from "@/js/interfaces/cart"

const props = defineProps<{
	customOrderAttributes: CustomOrderAttributeInterface[]
}>()

const form = reactive({})

// Initialize each checkbox attribute in the form as an empty array
props.customOrderAttributes.forEach(attribute => {
	if (attribute.type === 'checkbox') {
		form[attribute.key] = []
	}
})

</script>
<style scoped>
.main-label {
	@apply font-medium leading-5 text-gray-700;
}
</style>
