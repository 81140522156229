import {EventOptionInterface, EventOptionValueInterface} from "@/js/interfaces/option"

export function useEventOptionValue(id: number, option: EventOptionInterface): EventOptionValueInterface | null {
    if (!Array.isArray(option.values)) {
        return null
    }
    const filteredValueConfig = option.values.filter((value: EventOptionValueInterface) => {
        return value.id == id
    })
    if (
        Array.isArray(filteredValueConfig) &&
        typeof filteredValueConfig[0] == "object"
    ) {
        {
            return filteredValueConfig[0]
        }
    }
    return null
}
