<template>
	<div class="fixed left-0 right-0 bottom-0 flex md:hidden justify-center">
		<transition>
			<div v-if="shouldShow" class="w-66 bg-accent-orange text-white rounded-t shadow-md">
				<slot>
					Content here
				</slot>
			</div>
		</transition>
	</div>
</template>

<script lang="ts" setup>
import {useScrollPercentage} from '@/js/composables/documentFunctions'
import {computed, onMounted, ref} from "vue"

const props = withDefaults(defineProps<{
	show?: boolean
}>(), {
	show: true
})

const scrollOffset = ref(0)

const shouldShow = computed(() => props.show && scrollOffset.value > 50 && scrollOffset.value < 98)

const initializeScrollObserver = () => {
	window.onscroll = () => {
		scrollOffset.value = useScrollPercentage()
	}
}

onMounted(() => {
	initializeScrollObserver()
})
</script>
<!--suppress CssUnusedSymbol -->
<style>
.v-enter-active,
.v-leave-active {
	transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
	opacity: 0;
}
</style>
