<template>
	<div
			:class="
      modelValue
        ? 'bg-' + twColorBgSelected + ' border-' + twColorBorderSelected
        : 'bg-' + twColorBgUnselected + ' border-' + twColorBorderUnselected
    "
			class="relative border p-4 cursor-pointer"
			data-testid="wrapper"
			@click.stop="emitSelected"
	>
		<div class="flex">
			<div class="flex items-center h-5 w-5">
				<div
						v-if="modelValue"
						:class="'border-' + twColorSelected + ' text-' + twColorSelected"
						data-testid="checked"
				>
					<svg
							aria-hidden="true"
							height="1.4rem"
							preserveAspectRatio="xMidYMid meet"
							role="img"
							viewBox="0 0 24 24"
							width="1.4rem"
							xmlns="http://www.w3.org/2000/svg"
					>
						<g fill="none" stroke="currentColor" stroke-width="2">
							<path
									d="m8 12.5l3 3l5-6"
									stroke-linecap="round"
									stroke-linejoin="round"
							/>
							<circle cx="12" cy="12" r="10"/>
						</g>
					</svg>
				</div>
				<div
						v-else
						:class="'border-' + twColorUnselected + ' text-' + twColorUnselected"
						data-testid="unchecked"
				>
					<svg
							aria-hidden="true"
							height="1.4rem"
							preserveAspectRatio="xMidYMid meet"
							role="img"
							viewBox="0 0 24 24"
							width="1.4rem"
							xmlns="http://www.w3.org/2000/svg"
					>
						<circle
								cx="12"
								cy="12"
								fill="none"
								r="10"
								stroke="currentColor"
								stroke-width="2"
						/>
					</svg>
				</div>
			</div>
			<label
					class="ml-3 flex flex-col cursor-pointer w-full h-full"
					data-testid="label"
					style="display: block"
			>
        <span
		        :class="
            modelValue ? 'text-' + twColorSelected : 'text-' + twColorUnselected
          "
		        class="text-sm leading-5 font-medium"
        >
          <slot></slot>
        </span>
				<span
						:class="
            modelValue
              ? 'text-' + twColorCommentSelected
              : 'text-' + twColorCommentUnselected
          "
						class="text-sm leading-5 font-normal"
				>
          <slot name="comment"></slot>
        </span>
			</label>
		</div>
		<div v-if="modelValue"
		     class="ml-8 mt-2 border border-secondary p-4"
		>
			<p class="text-md"><strong>Modul-Termine</strong></p>
			<ModuleDatesSelector
					v-model="proposedDates"
					:courseEvent="courseEvent"
					:eventdate="eventdate"
			></ModuleDatesSelector>
		</div>
	</div>
</template>

<script lang="ts" setup>
import ModuleDatesSelector from "./ModuleDatesSelector.vue"
import {EventdateInterface} from "@/js/interfaces/eventdate"
import {CartEventInterface} from "@/js/interfaces/event"
import {ref, watch} from "vue"

const props = withDefaults(defineProps<{
	modelValue?: boolean
	courseEvent: CartEventInterface
	useProposedDates?: boolean
	id: number
	eventdate: EventdateInterface
	name: string | number
	twColorBgSelected?: string
	twColorBgUnselected?: string
	twColorBorderSelected?: string
	twColorBorderUnselected?: string
	twColorSelected?: string
	twColorUnselected?: string
	twColorCommentSelected?: string
	twColorCommentUnselected?: string
}>(), {
	modelValue: false,
	twColorBgSelected: "white",
	twColorBgUnselected: "white",
	twColorBorderSelected: "blue-500",
	twColorBorderUnselected: "gray-300",
	twColorSelected: "primary",
	twColorUnselected: "gray-500",
	twColorCommentSelected: "blue-700",
	twColorCommentUnselected: "gray-500",
})

const emit = defineEmits(["UseProposedDates", "update:modelValue"])

const proposedDates = ref<number[]>([])

watch(proposedDates, () => {
	emitProposedDates()
})

const emitSelected = () => {
	emit("update:modelValue", !props.modelValue)
}

const emitProposedDates = () => {
	emit('UseProposedDates', proposedDates.value)
}
</script>
