<template>
	<div class="flex flex-wrap mb-6 w-full">
		<div class="w-full md:w-1/2 md:pr-2 xl:w-1/4 xl:pr-2 mb-2">
			<label
					class="text-sm font-medium leading-5 text-gray-700"
					for="subscriber-firstname"
			>
				Vorname
			</label>
			<div class="mt-1 shadow-sm relative">
				<input
						id="subscriber-firstname"
						v-model="subscriber.firstname"
						:class="
            getFieldError('firstname')
              ? 'border-red-500'
              : ''
          "
						class="transition-colors form-input w-full sm:text-sm sm:leading-5 rounded-none pr-10"
						placeholder="Hans"
						type="text"
				/>
				<ErrorExclamationMark
						v-if="getFieldError('firstname')"
				></ErrorExclamationMark>
			</div>
			<ErrorMessagesDisplay
					:error="getFieldError('firstname')"
			></ErrorMessagesDisplay>
		</div>
		<div class="w-full md:w-1/2 xl:w-1/4 xl: pr-2 mb-2">
			<label
					class="text-sm font-medium leading-5 text-gray-700"
					for="subscriber-lastname"
			>
				Nachname
			</label>
			<div class="mt-1 relative shadow-sm">
				<input
						id="subscriber-lastname"
						v-model="subscriber.lastname"
						:class="
            getFieldError('lastname')
              ? 'border-red-500'
              : ''
          "
						class="form-input w-full sm:text-sm sm:leading-5 rounded-none"
						placeholder="Muster"
						type="text"
				/>
				<ErrorExclamationMark
						v-if="getFieldError('lastname')"
				></ErrorExclamationMark>
			</div>
			<ErrorMessagesDisplay
					:error="getFieldError('lastname')"
			></ErrorMessagesDisplay>
		</div>
		<div class="w-full md:w-1/2 xl:w-1/4 md:pr-2 mb-2">
			<label
					class="text-sm font-medium leading-5 text-gray-700"
					for="subscriber-position"
			>
				Funktion
			</label>
			<div class="mt-1 relative shadow-sm">
				<input
						id="subscriber-position"
						v-model="subscriber.position"
						:class="
            getFieldError('position')
              ? 'border-red-500'
              : ''
          "
						class="form-input w-full sm:text-sm sm:leading-5 rounded-none"
						placeholder="Office Manager"
						type="text"
				/>
				<ErrorExclamationMark
						v-if="getFieldError('position')"
				></ErrorExclamationMark>
			</div>
			<ErrorMessagesDisplay
					:error="getFieldError('position')"
			></ErrorMessagesDisplay>
		</div>
		<div class="w-full md:w-1/2 xl:w-1/4 mb-2 md:pr-2">
			<label
					class="text-sm font-medium leading-5 text-gray-700"
					for="subscriber-email"
			>
				E-Mail
			</label>
			<div class="mt-1 relative shadow-sm">
				<input
						id="subscriber-email"
						v-model="subscriber.email"
						:class="
            getFieldError('email') ? 'border-red-500' : ''
          "
						class="form-input w-full sm:text-sm sm:leading-5 rounded-none"
						placeholder="hans.muster@test.com"
						type="text"
				/>
				<ErrorExclamationMark
						v-if="getFieldError('email')"
				></ErrorExclamationMark>
			</div>
			<ErrorMessagesDisplay
					:error="getFieldError('email')"
			></ErrorMessagesDisplay>
		</div>
	</div>
</template>

<script lang="ts" setup>
import ErrorExclamationMark from "../../ui/ErrorExclamationMark.vue"
import ErrorMessagesDisplay from "../../ui/ErrorMessagesDisplay.vue"
import {useFieldError} from '@/js/composables/vuelidate'
import {ReissueSubscriberInterface} from "@/js/interfaces/reissue_notification"
import {ErrorObject} from "@vuelidate/core"
import {computed} from "vue"


const props = defineProps<{
	modelValue: ReissueSubscriberInterface,
	errors: ErrorObject[]
}>()

const emit = defineEmits(["update:modelValue"])

const subscriber = computed({
	get() {
		return props.modelValue
	},
	set(subscriber) {
		emit("update:modelValue", subscriber)
	},
})

const getFieldError = (propertyName: string) => {
	return useFieldError(propertyName, props.errors)
}
</script>
