<template>
	<div v-if="!optionIsDisabledBecauseItHasNoValues" data-testid="option">
		<div class="text-primary text-md bold uppercase mb-2" data-testid="options-title">
			{{ option.title }}
			<RequiredStar :modelValue="option.is_required"></RequiredStar>
		</div>
		<div class="space-y-2">
			<RadioGroup
					:radio-values="option.values"
					:required="option.is_required"
					@update:modelValue="selectRadio"
			></RadioGroup>
		</div>
	</div>
</template>

<script lang="ts" setup>
import {computed, reactive} from "vue"
import {EventOptionInterface, selectedOptionInterface} from "@/js/interfaces/option"
import RequiredStar from "@/js/components/ui/RequiredStar.vue"
import {useEventOptionValue} from "@/js/components/checkout/cart/composables/option"
import RadioGroup from "@/js/components/ui/RadioGroup.vue"

const props = defineProps<{
	modelValue?: selectedOptionInterface | boolean
	option: EventOptionInterface
}>()

const emit = defineEmits(["update:modelValue"])

const selected = reactive<{ [key: number]: boolean }>({})

const optionIsDisabledBecauseItHasNoValues = computed(() => {
	if (Object.prototype.hasOwnProperty.call(props.option, "values") === false || !props.option.values) {
		return true
	}
	return props.option.values.length === 0
})

const selectRadio = (value: number) => {
	for (let key in selected) {
		selected[key] = false
	}
	selected[value] = true
	emitSelectedAsOptionValues()
}

const emitSelectedAsOptionValues = () => {
	let hasSelected = false
	let selectedValues = {}
	for (let key in selected) {
		if (selected[key] === true) {
			hasSelected = true
			selectedValues[key] = useEventOptionValue(parseInt(key), props.option)
		}
	}
	emit("update:modelValue", hasSelected ? selectedValues : false)
}
</script>
