<template>
	<div class="w-full shadow-xl border border-header text-defaulttext">
		<div v-if="$slots['header']" class="bg-header w-full p-4 font-semibold">
			<slot name="header"></slot>
		</div>
		<div>
			<slot></slot>
		</div>
	</div>
</template>
