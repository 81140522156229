<template>
	<div class="space-y-4">
		<div v-for="option in options" :key="option.id">
			<template v-if="option.type === 'checkbox'">
				<CheckboxOption
						:ref="getItemRef"
						v-model="selected[option.id]"
						:option="option"
				></CheckboxOption>
			</template>
			<template v-if="option.type === 'radio'">
				<RadioOption
						v-model="selected[option.id]"
						:option="option"
				></RadioOption>
			</template>
		</div>
	</div>
</template>

<script lang="ts" setup>
import {onBeforeUpdate, onMounted, reactive, watch} from "vue"
import {EventOptionInterface, SelectedOptionsInterface} from "@/js/interfaces/option"
import RadioOption from "@/js/components/checkout/cart/options/RadioOption.vue"
import CheckboxOption from "@/js/components/checkout/cart/options/CheckboxOption.vue"


const props = defineProps<{
	modelValue: SelectedOptionsInterface | null
	options: EventOptionInterface[]
}>()

const emit = defineEmits(["update:modelValue"])

const selected = reactive<SelectedOptionsInterface>({})

const initOptions = () => {
	props.options.forEach((option) => {
		selected[option.id] = false
	})
}

/** Validation of mandatory checkboxes **/

let itemRefs: any[] = []

const getItemRef = (ref) => {
	if (!ref) {
		return
	}
	itemRefs.push(ref)
}

onBeforeUpdate(() => {
	itemRefs = []
})

const validateCheckboxes = (): boolean => {
	itemRefs.forEach((ref) => {
		const hasError = ref.validate()
		if (hasError) {
			return false
		}
	})
	return true
}

defineExpose({
	validateCheckboxes
})

/** END Validation of mandatory checkboxes **/


watch(selected, () => {
	emit("update:modelValue", selected)
}, {deep: true})

onMounted(() => {
	initOptions()
})
</script>
