<script setup lang="ts">
import {AssetInterface} from "@/js/interfaces/asset"
import {Carousel, Slide} from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
import {useTracking} from "@/js/composables/plausible"
import {computed} from "vue"

const props = defineProps<{
	assets: AssetInterface[];
}>()

const tracking = useTracking()

const sortedAssets = computed(() => {
	return [...props.assets].sort((a, b) => (a.position ?? 0) - (b.position ?? 0))
})
const trackClick = (asset: AssetInterface) => {
	const currentPagePath = window.location.pathname
	const assetUrlPath = new URL(asset.destination_url).pathname
	tracking.campaignClick('Asset Banner', assetUrlPath + ', clicked on ' + currentPagePath)
}
</script>

<template>
	<carousel :autoplay="sortedAssets.length > 1 ? 5000 : 0" :items-to-show="1" :pause-autoplay-on-hover="true"
	          :transition="1800" :wrap-around="true">
		<slide v-for="asset in sortedAssets" :key="asset.id">
			<a :href="asset.destination_url" @click="trackClick(asset)"><img :src="asset.path"
			                                                                 :title="asset.destination_url"/></a>
		</slide>
	</carousel>
</template>

<style>
.carousel__track {
	transition-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28) !important;
	/**
	transition-timing-function: ease-in-out !important;
	 */
}
</style>
