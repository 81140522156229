<template>
	<div class="mb-8">
		<h2 class="margin-top-medium">Module und Termine</h2>
		<p>
			Im Rahmen dieser modularen Weiterbildung besuchen Sie die folgenden
			Seminarmodule
			<sup>*</sup>
			:
		</p>
		<div
				:class="
        'mb-4 sm:grid sm:grid-cols-2 sm:gap-4 xl:grid-cols-' + modules.length
      "
		>
			<Module
					v-for="module in modules"
					:key="module.id"
					:module="module"
					class="mb-2 sm:mb-0"
			></Module>
		</div>
		<div class="text-sm">
			<p>
				<sup>*</sup>
				Grundsätzlich können Sie für jedes Seminarmodul die gewünschte
				Durchführung
				<strong>frei wählen.</strong>
				<template v-if="hasMandatoryPositions">
					Wir empfehlen jedoch, dass Sie die
					<strong>angegebene Reihenfolge einhalten.</strong>
				</template>
			</p>
		</div>
	</div>
</template>

<script lang="ts" setup>
import Module from "./Module.vue"
import {CourseModuleInterface} from "@/js/interfaces/course"
import {computed} from "vue"

const props = defineProps<{
	modules: CourseModuleInterface[]
}>()

const hasMandatoryPositions = computed(() => {
	let modulesWithMandatoryPosition = props.modules.filter((module) => {
		return module.module_position > 0
	})
	return modulesWithMandatoryPosition.length
})
</script>
