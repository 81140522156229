<template>
	<div v-if="customerAddresses">
		<div
				:class="show ? 'mb-2' : 'mb-4'"
				class="cursor-pointer inline-block"
				@click="showSelector"
		>
			Gespeicherte Addresse wählen
			<svg
					:class="show ? '-rotate-180' : ''"
					class="-mt-0.5 transition-transform duration-150 transform inline-block w-3 h-3 cursor-pointer"
					fill="none"
					stroke="currentColor"
					viewBox="0 0 24 24"
					xmlns="http://www.w3.org/2000/svg"
			>
				<path
						d="M19 9l-7 7-7-7"
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="2"
				/>
			</svg>
		</div>
		<div v-if="show">
			<RadioGroup
					v-model="address"
					:radio-values="addressOptions"
					class="mb-4"
			></RadioGroup>
		</div>
	</div>
</template>

<script lang="ts" setup>
import RadioGroup from '../../ui/RadioGroup.vue'
import {computed, ref, watch} from "vue"
import {useStore} from "@/js/store/store"

const store = useStore()

const props = defineProps<{
	modelValue?: number | null,
}>()

const emit = defineEmits(["update:modelValue", "open", "close"])

const show = ref(false)

const address = computed({
	get: () => {
		return props.modelValue ? props.modelValue : null
	},
	set: (value) => {
		emit("update:modelValue", value)
	}
})

const customerAddresses = computed(() => {
	return store.getters['customerAddresses']
})

const addressOptions = computed(() => {
	if (!customerAddresses.value) return null
	let addresses = JSON.parse(JSON.stringify(customerAddresses.value.all))
	addresses.map((address) => {
		address.title = getAsString(address)
	})
	return addresses
})

watch(address, () => {
	hideSelector()
})

const hideSelector = () => {
	show.value = false
	emit("close")
}

const showSelector = () => {
	show.value = true
	emit("open")
}

const getAsString = (address) => {
	let addressString = ''
	if (address.company) addressString += address.company + ', '
	addressString +=
			address.firstname + ' ' + address.lastname + ', '
	addressString += address.street.join(', ') + ', '
	addressString +=
			address.country_id +
			'-' +
			address.postcode +
			' ' +
			address.city
	return addressString
}
</script>
