<template>
	<div class="social-buttons">
		<share-network
				:description="description"
				:hashtags="hashtags"
				:media="shareImage"
				:title="title"
				:url="path"
				class="share-on-facebook text-primary hover:text-primary-light email"
				network="facebook"
		>
			<font-awesome-icon :icon="['fab', 'facebook']"></font-awesome-icon>
		</share-network>
		<share-network
				:description="description"
				:hashtags="hashtags"
				:media="shareImage"
				:title="title"
				:url="path"
				class="share-on-twitter text-primary hover:text-primary-light email ml-6"
				network="twitter"
				@open="sharing"
		>
			<font-awesome-icon :icon="['fab', 'twitter']"></font-awesome-icon>
		</share-network>
		<share-network
				:description="description"
				:hashtags="hashtags"
				:media="shareImage"
				:title="title"
				:url="path"
				class="share-via-email text-primary hover:text-primary-light email ml-6"
				network="email"
				@open="sharing"
		>
			<font-awesome-icon icon="envelope"></font-awesome-icon>
		</share-network>
	</div>
</template>

<!--suppress TypeScriptCheckImport -->
<script lang="ts" setup>
import ShareNetwork from "vue-social-sharing/src/share-network"
import {computed} from "vue"
import {useTracking} from "@/js/composables/plausible"

const tracking = useTracking()

const sharing = (network: string) => {
	const currentPagePath = window.location.pathname
	tracking.social(network, currentPagePath + " - shared on " + network)
}
const props = defineProps({
	image: {
		type: String,
		required: false,
		default: null,
	},
})

const title = computed((): string => {
	return window.document.title
})
const description = computed(() => {
	if (!window.document.head.querySelector("meta[name='description']")) return null
	const description = window.document.head.querySelector("meta[name='description']") as HTMLMetaElement
	return description.content
})

const shareImage = computed(() => {
	if (props.image) return props.image
	return socialImage.value
})

const path = window.location.href

const hashtags = "Seminare,Kurse"

const socialImage = computed(() => {
	const primaryImage = window.document.body.querySelector("#main-image")
	if (!primaryImage) {
		return null
	}
	return primaryImage.getAttribute("src")
})
</script>
<style>
.social-buttons a {
	display: inline-block;
	cursor: pointer;
}
</style>
