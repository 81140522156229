<template>
	<form id="registerform">
		<div class="mb-8">
			<p class="text-3xl font-extrabold text-gray-900">
				Interessiert? Kontaktieren Sie uns sofort.
			</p>
			<p class="mt-2 text-lg text-gray-500">
				Viele namhafte Veranstaltungspartner nutzen seminar-portal.ch seit
				Jahren als wichtigen zusätzlichen Vertriebskanal.
				<strong>Profitieren ab sofort auch Sie!</strong>
			</p>
			<p class="mt-2 text-lg text-gray-500">
				Signalisieren Sie uns Ihr Interesse mit dem untenstehenden Formular.
				Nach einer kurzen Prüfung Ihres Veranstaltungsangebots stellen wir Ihnen
				den Standard-Zusammenarbeitsvertrag zu, sodass
				<strong>
					die ersten Veranstaltungen innert kürzester Zeit aufgeschaltet
				</strong>
				werden können.
			</p>
		</div>

		<fieldset
				id="form-start"
				class="rounded-xl shadow-md p-6 bg-gray-200 border-primary text-gray-500"
		>
			<div class="fieldrow">
				<label for="company">Firma</label>
				<div class="mt-1 relative shadow-sm">
					<input
							id="company"
							v-model="form.company"
							placeholder="Muster AG"
							type="text"
					/>
				</div>
			</div>
			<div class="fieldrow">
				<label for="firstname">Vorname</label>
				<div class="mt-1 relative shadow-sm">
					<input
							id="firstname"
							v-model="form.firstname"
							:class="getFieldError('firstname') ? 'border-red-500' : ''"
							placeholder="Hans"
							type="text"
					/>
					<ErrorExclamationMark
							v-if="getFieldError('firstname')"
					></ErrorExclamationMark>
				</div>
				<ErrorMessagesDisplay
						:error="getFieldError('firstname')"
				></ErrorMessagesDisplay>
			</div>
			<div class="fieldrow">
				<label for="lastname">Nachname</label>
				<div class="mt-1 relative shadow-sm">
					<input
							id="lastname"
							v-model="form.lastname"
							:class="getFieldError('lastname') ? 'border-red-500' : ''"
							placeholder="Muster"
							type="text"
					/>
					<ErrorExclamationMark
							v-if="getFieldError('lastname')"
					></ErrorExclamationMark>
				</div>
				<ErrorMessagesDisplay
						:error="getFieldError('lastname')"
				></ErrorMessagesDisplay>
			</div>
			<div class="fieldrow">
				<label for="street">Strasse</label>
				<div class="mt-1 relative shadow-sm">
					<div class="flex w-full">
						<AddressInput id="street" v-model="form.street"></AddressInput>
					</div>
				</div>
				<ErrorMessagesDisplay
						:error="getFieldError('street')"
				></ErrorMessagesDisplay>
			</div>
			<div class="fieldrow lg:flex lg:space-x-2">
				<div class="lg:w-1/5 mb-4 lg:mb-0">
					<label for="postcode">PLZ</label>
					<div class="mt-1 relative shadow-sm">
						<input
								id="postcode"
								v-model="form.postcode"
								:class="getFieldError('postcode') ? 'border-red-500' : ''"
								placeholder="6004"
								type="text"
						/>
						<ErrorExclamationMark
								v-if="getFieldError('postcode')"
						></ErrorExclamationMark>
					</div>
					<ErrorMessagesDisplay
							:error="getFieldError('postcode')"
					></ErrorMessagesDisplay>
				</div>
				<div class="lg:w-4/5">
					<label for="city">Ort</label>
					<div class="mt-1 relative shadow-sm">
						<input
								id="city"
								v-model="form.city"
								:class="getFieldError('city') ? 'border-red-500' : ''"
								placeholder="Musterhausen"
								type="text"
						/>
						<ErrorExclamationMark
								v-if="getFieldError('city')"
						></ErrorExclamationMark>
					</div>
					<ErrorMessagesDisplay
							:error="getFieldError('city')"
					></ErrorMessagesDisplay>
				</div>
			</div>
			<div class="fieldrow lg:flex lg:space-x-2">
				<div class="lg:w-1/2 mb-4 lg:mb-0">
					<label for="email">E-Mail</label>
					<div class="mt-1 relative shadow-sm">
						<input
								id="email"
								v-model="form.email"
								:class="getFieldError('email') ? 'border-red-500' : ''"
								placeholder="hans.muster@test.com"
								type="email"
						/>
						<ErrorExclamationMark
								v-if="getFieldError('email')"
						></ErrorExclamationMark>
					</div>
					<ErrorMessagesDisplay
							:error="getFieldError('email')"
					></ErrorMessagesDisplay>
				</div>
				<div class="lg:w-1/2">
					<label for="telephone">Telefon</label>
					<div class="mt-1 relative shadow-sm">
						<input
								id="telephone"
								v-model="form.telephone"
								:class="getFieldError('telephone') ? 'border-red-500' : ''"
								placeholder="++41 76 999 99 99"
								type="text"
						/>
						<ErrorExclamationMark
								v-if="getFieldError('telephone')"
						></ErrorExclamationMark>
					</div>
					<ErrorMessagesDisplay
							:error="getFieldError('telephone')"
					></ErrorMessagesDisplay>
				</div>
			</div>
			<div class="fieldrow sm:flex sm:space-x-2">
				<div class="sm:w-1/5 mb-4 sm:mb-0">
					<label for="amount">Seminare pro Jahr (ca.)</label>
					<div class="mt-1 relative shadow-sm">
						<input
								id="amount"
								v-model="form.amount"
								:class="getFieldError('amount') ? 'border-red-500' : ''"
								placeholder="12"
								type="text"
						/>
						<ErrorExclamationMark
								v-if="getFieldError('amount')"
						></ErrorExclamationMark>
					</div>
					<ErrorMessagesDisplay
							:error="getFieldError('amount')"
					></ErrorMessagesDisplay>
				</div>
				<div class="sm:w-4/5">
					<label for="comment">Ihre Nachricht</label>
					<div class="mt-1 relative shadow-sm">
            <textarea
		            id="comment"
		            v-model="form.comment"
		            :class="getFieldError( 'comment') ? 'border-red-500' : ''"
		            rows="5"
            ></textarea>
						<ErrorExclamationMark
								v-if="getFieldError( 'comment')"
						></ErrorExclamationMark>
					</div>
					<ErrorMessagesDisplay
							:error="getFieldError( 'comment')"
					></ErrorMessagesDisplay>
				</div>
			</div>
			<div class="fieldrow flex justify-end space-x-2">
				<div
						class="cursor-pointer mt-8 w-full bg-accent-orange border border-transparent px-5 py-3 inline-flex items-center justify-center text-base font-medium rounded-md text-white hover:bg-hover-orange hover:text-white sm:mt-10 sm:w-auto xl:mt-0 shadow-md"
						@click.stop="submit"
				>
					Formular absenden
				</div>
			</div>
		</fieldset>
	</form>
</template>

<script lang="ts" setup>
import ErrorExclamationMark from "../../ui/ErrorExclamationMark.vue"
import ErrorMessagesDisplay from "../../ui/ErrorMessagesDisplay.vue"
import AddressInput from "../../checkout/AddressInput.vue"
import * as Sentry from "@sentry/vue"
import {joinAsPartner} from "@/js/api/sp"
import useVuelidate from "@vuelidate/core"
import {email, helpers, minLength, numeric, required} from "@vuelidate/validators"
import {useFieldError} from "@/js/composables/vuelidate"
import {RegisterFormInterface} from "@/js/interfaces/partner_marketing"
import {computed, inject, reactive} from "vue"
import {ToastPluginApi} from "vue-toast-notification"

const messages = {
	required: 'Dies ist ein Pflichtfeld',
	email: 'Bitte geben Sie eine gültige E-Mail-Adresse ein',
	number: 'Numerische Eingabe erforderlich'
}
const toast = inject<ToastPluginApi>('toast')!

const props = defineProps<{
	url: string
}>()

const form = reactive<RegisterFormInterface>({
			company: null,
			firstname: null,
			lastname: null,
			street: [],
			postcode: null,
			city: null,
			email: null,
			telephone: null,
			amount: null,
			comment: null,
		}
)

const formValidations = {
	firstname: {required: helpers.withMessage(messages.required, required)},
	lastname: {required: helpers.withMessage(messages.required, required)},
	street: {
		required: helpers.withMessage(messages.required, required),
		minLength: helpers.withMessage(messages.required, minLength(1))
	},
	postcode: {required: helpers.withMessage(messages.required, required)},
	city: {required: helpers.withMessage(messages.required, required)},
	email: {
		required: helpers.withMessage(messages.required, required),
		email: helpers.withMessage(messages.email, email)
	},
	telephone: {required: helpers.withMessage(messages.required, required)},
	amount: {
		required: helpers.withMessage(messages.required, required),
		numeric: helpers.withMessage(messages.number, numeric)
	}
}

const formValidation = useVuelidate(formValidations, form)

const errors = computed(() => {
	if (!formValidation.value.$errors) {
		return null
	}
	return formValidation.value.$errors
})

const getFieldError = (propertyName: string) => {
	return useFieldError(propertyName, errors.value)
}

const submit = async () => {
	const result = await formValidation.value.$validate()
	if (result) {
		try {
			await joinAsPartner(form, props.url)
			toast.success(
					"Vielen Dank für Ihre Kontaktaufnahme. Wir werden uns in Kürze bei Ihnen melden."
			)
			window.location.href = "/"
		} catch (e) {
			toast.error(
					"Das Formular konnte nicht übermittelt werden. Bitte wenden Sie sich per Telefon oder Kontaktformular an uns."
			)
		}
	} else {
		toast.error("Da scheint etwas noch nicht ganz zu stimmen. Bitte prüfen Sie die Eingabefelder...")
		const formElement = document.getElementById("form-start")!
		formElement.scrollTo({behavior: "smooth"})
		Sentry.captureMessage("Form validation failed", "info")
	}
}
</script>

<style scoped>
.fieldrow {
	@apply mb-4;
}

label {
	@apply font-medium leading-5 text-gray-700;
}

input,
textarea {
	@apply w-full;
}
</style>
