<template>
	<UiModal ref="questionModal">
		<template v-slot:opener>
			<div class="flex items-center">
				<div class="w-xs">
					<div>
						<i class="fas fa-question-circle fa-fw fa-2x text-primary pr-2"></i>
					</div>
				</div>
				<div class="flex-grow">
					<div class="text-sm">
            <span class="tw-block w-full h-full">
              Frage zu dieser Veranstaltung stellen
            </span>
					</div>
				</div>
			</div>
		</template>
		<template v-slot:title>Frage zu «{{ event.title }}» stellen</template>
		<p class="mb-4">
			Stellen Sie hier Ihre Frage. Wir melden uns dann so schnell wie möglich
			bei Ihnen.
		</p>
		<QuestionForm
				v-model="questionForm"
				:errors="formValidation.$errors"
				:event="event"
		></QuestionForm>
		<template v-slot:footer>
			<div class="flex justify-between">
				<div class="text-sm"></div>
				<div>
					<button class="button" type="button" @click.stop="submit">Senden</button>
				</div>
			</div>
		</template>
	</UiModal>
</template>

<script lang="ts" setup>
import UiModal from '../../ui/UiModal.vue'
import QuestionForm from './QuestionForm.vue'
import {inject, ref} from 'vue'
import useVuelidate from "@vuelidate/core"
import {CartEventInterface} from "@/js/interfaces/event"
import {askQuestion} from "@/js/api/sp"
import axios from "axios"
import {email, helpers, required} from "@vuelidate/validators"
import {ToastPluginApi} from "vue-toast-notification"
import {QuestionFormInterface} from "@/js/interfaces/q_and_a"

const toast = inject<ToastPluginApi>('toast')!

const messages = {
	required: 'Dies ist ein Pflichtfeld',
	email: 'Bitte geben Sie eine gültige E-Mail-Adresse ein'
}

const questionModal = ref()

const props = withDefaults(defineProps<{
	OpenerText?: string,
	event: CartEventInterface,
	submitUrl: string,
}>(), {
	OpenerText: 'Frage zu dieser Veranstaltung stellen',
})

const questionForm = ref<QuestionFormInterface>({
	relation_type: 'catalog/product',
	relation_id: props.event.id,
	asker_name: null,
	asker_email: null,
	asker_phone: null,
	asker_contact_preference: 'email',
	question: null,
})

const formValidations = {
	asker_name: {required: helpers.withMessage(messages.required, required)},
	asker_email: {
		required: helpers.withMessage(messages.required, required),
		email: helpers.withMessage(messages.email, email)
	},
	question: {required: helpers.withMessage(messages.required, required)},
}

const formValidation = useVuelidate(formValidations, questionForm)


const submit = async () => {
	const result = await formValidation.value.$validate()
	if (result) {
		try {
			const response = await askQuestion(questionForm.value, props.submitUrl)
			toast.success(response)
			questionModal.value.hideModal()
		} catch (err) {
			if (axios.isAxiosError(err) && err.response) {
				toast.error(err.response.data)
			}
		}
	} else {
		toast.error(
				'Bitte prüfen Sie die Feldeingaben...da stimmt noch etwas nicht ganz.'
		)
	}
}
</script>
