<template>
	<Listbox v-model="selected" as="div">
		<div class="relative mt-1">
			<ListboxButton
					class="cursor-default relative w-full border border-inputborder bg-white pl-3 pr-10 py-2 text-left focus:outline-none focus:ring-1 focus:ring-inputfocusborder transition ease-in-out duration-150 sm:text-sm sm:leading-5"
			><span class="tw-block truncate">{{ selected?.name }}</span>
				<span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <ChevronDownIcon aria-hidden="true" class="h-5 w-5 text-gray-400"/>
        </span>
			</ListboxButton>
			<template v-if="options && options.length > 0">
				<transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100"
				            leave-to-class="opacity-0">
					<ListboxOptions
							class="absolute z-10 mt-1 max-h-60 w-full overflow-auto bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
						<ListboxOption v-for="option in options" :key="option.id" v-slot="{ active, selected }" :value="option"
						               as="template">
							<li :class="[active ? 'text-white bg-primary' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
								<span :class="[selected ? 'font-semibold' : 'font-normal', 'tw-block truncate']">{{
										option.name
									}}</span>

								<span v-if="selected"
								      :class="[active ? 'text-white' : 'text-primary', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                <CheckIcon aria-hidden="true" class="h-5 w-5" data-testid="selected"/>
              </span>
							</li>
						</ListboxOption>
					</ListboxOptions>
				</transition>
			</template>
		</div>
	</Listbox>
</template>

<script lang="ts" setup>
import {onMounted, ref, watch} from 'vue'
import {Listbox, ListboxButton, ListboxOption, ListboxOptions} from '@headlessui/vue'
import {CheckIcon, ChevronDownIcon} from '@heroicons/vue/solid'

interface SelectOptioninterface {
	id: number | string
	name: string
}

const props = withDefaults(defineProps<{
	modelValue?: number | string
	options: SelectOptioninterface[]
}>(), {})


const emit = defineEmits(["update:modelValue"])

const selected = ref<SelectOptioninterface | undefined>(props.options[0])

watch(() => props.modelValue, (id) => {
	if (id !== undefined) {
		selected.value = props.options.find((option) => option.id === id)
	}
})

watch(selected, (option) => {
	if (option !== undefined) {
		emit("update:modelValue", option.id)
	}
})
onMounted(() => {
	if (props.modelValue) {
		selected.value = props.options.find((option) => option.id === props.modelValue)
	}
})
</script>
