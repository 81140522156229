<template>
	<UiModal ref="subscriptionModal">
		<template v-slot:opener>
			<div class="flex items-center">
				<div class="w-xs">
					<div>
						<i class="fa fa-calendar fa-fw fa-2x text-green-800 pr-2"></i>
					</div>
				</div>
				<div class="flex-grow">
					<div class="text-sm">
            <span class="tw-block w-full h-full">
              Informieren Sie mich über neue Termine
            </span>
					</div>
				</div>
			</div>
		</template>
		<template v-slot:title>Automatische Termin-Benachrichtigung</template>
		<p>
			Jetzt hier Abonnieren und automatisch eine E-Mail bekommen, wenn für die
			Veranstaltung «{{ event.title }}» neue Termine bekannt werden
			<sup>*</sup>
		</p>
		<SubscriptionForm
				v-model="subscriber"
				:errors="errors"
				:event="event"
		></SubscriptionForm>
		<template v-slot:footer>
			<div class="flex justify-between">
				<div class="text-sm">
					<sup>*</sup>
					Sie können die Benachrichtigung in jeder Benachrichtigungs-E-Mail
					wieder deaktivieren.
				</div>
				<div>
					<button class="button inline-block" type="button" @click.stop="submit">
						Abonnieren
					</button>
				</div>
			</div>
		</template>
	</UiModal>
</template>

<script lang="ts" setup>
import UiModal from "../../ui/UiModal.vue"
import SubscriptionForm from "./SubscriptionForm.vue"
import {computed, inject, ref} from 'vue'
import useVuelidate from "@vuelidate/core"
import {subscribeToNotification} from "@/js/api/sp"
import axios from "axios"
import {email, helpers, required} from "@vuelidate/validators"
import {ReissueSubscriberInterface} from "@/js/interfaces/reissue_notification"
import {ToastPluginApi} from "vue-toast-notification"
import {CartEventInterface} from "@/js/interfaces/event"

const toast = inject<ToastPluginApi>('toast')!

const props = defineProps<{
	event: CartEventInterface
	submitUrl: string
}>()

const messages = {
	required: 'Dies ist ein Pflichtfeld',
	email: 'Bitte geben Sie eine gültige E-Mail-Adresse ein'
}

const subscriber = ref<ReissueSubscriberInterface>({
	event_id: props.event.id,
	firstname: null,
	lastname: null,
	position: null,
	email: null,
})


const subscriberValidationRules = {
	firstname: {
		required: helpers.withMessage(messages.required, required),
	},
	lastname: {
		required: helpers.withMessage(messages.required, required),
	},
	position: {
		required: helpers.withMessage(messages.required, required),
	},
	email: {
		required: helpers.withMessage(messages.required, required),
		email: helpers.withMessage(messages.email, email),
	},
}

const subscriberValidation = useVuelidate(subscriberValidationRules, subscriber)

const subscriptionModal = ref()

const errors = computed(() => {
	return subscriberValidation.value.$errors
})

const submit = async () => {
	const result = await subscriberValidation.value.$validate()
	if (result) {
		try {
			const response = await subscribeToNotification(subscriber.value, props.submitUrl)
			toast.success(response)
			subscriptionModal.value.hideModal()
		} catch (err) {
			if (axios.isAxiosError(err) && err.response) {
				toast.error(err.response.data)
			}
		}
	} else {
		toast.error(
				"Bitte prüfen Sie die Feldeingaben...da stimmt etwas noch nicht ganz."
		)
	}
}
</script>
