<template>
	<div class="flex w-full" @mouseleave="setIsHovered(0)" data-testid="rating-star-element">
		<button v-for="i in 5" :key="i" :class="[{ 'mr-1': i < 5 }, starClass(i)]" type="button"
		        @click="$emit('update:modelValue', i)"
		        :data-testid="'star-' + i" @mouseover="setIsHovered(i)">
			<svg class="tw-block w-full" fill="currentColor"
			     viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
				<path
						d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z"/>
			</svg>
		</button>
	</div>
</template>

<script lang="ts" setup>
import {ref} from "vue"

const props = withDefaults(defineProps<{
	modelValue?: number
	activeColorTwCode?: string
	inactiveColorTwCode?: string
	hoverColorTwCode?: string
}>(), {
	modelValue: 0,
	activeColorTwCode: 'blue-500',
	inactiveColorTwCode: 'gray-300',
	hoverColorTwCode: 'blue-300',
})

defineEmits(['update:modelValue'])

const starClass = (i: number) => {
	if (props.modelValue >= i) {
		return 'text-' + props.activeColorTwCode
	}
	if (hovered.value >= i) {
		return 'text-' + props.hoverColorTwCode
	}
	return 'text-' + props.inactiveColorTwCode
}

const hovered = ref(0)

const setIsHovered = (i: number) => {
	hovered.value = i
}
</script>
<style scoped>
.dummy {
	@apply stroke-1;
}
</style>
