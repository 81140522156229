<template>
	<div class="relative">
		<div
				:class="menuOpen ? 'text-secondary' : 'text-white'"
				class="cursor-pointer pt-1.5 h-full flex flex-col justify-center uppercase hover:text-secondary"
				@click="menuOpen = !menuOpen"
		>
      <span class="inline-block">
        <span class="inline-block">Konto</span>
        <svg
		        :class="menuOpen ? 'rotate-180' : ''"
		        class="ml-1 -mt-0.5 transition-transform duration-150 transform inline-block w-3 h-3 cursor-pointer"
		        fill="none"
		        stroke="currentColor"
		        viewBox="0 0 24 24"
		        xmlns="http://www.w3.org/2000/svg"
        >
          <path
		          d="M19 9l-7 7-7-7"
		          stroke-linecap="round"
		          stroke-linejoin="round"
		          stroke-width="2"
          />
        </svg>
      </span>
		</div>

		<transition
				enter-active-class="transition ease-out duration-150"
				enter-class="opacity-0 translate-y-1"
				enter-to-class="opacity-100 translate-y-0"
				leave-active-class="transition ease-in duration-180"
				leave-class="opacity-100 translate-y-0"
				leave-to-class="opacity-0 translate-y-1"
		>
			<div
					v-if="menuOpen"
					v-on-clickaway="closeMenu"
					class="absolute z-10 transform px-2 right-0 sm:px-0 whitespace-nowrap"
			>
				<div
						class="flex flex-col shadow-lg border border-header overflow-hidden text-defaulttext bg-white"
				>
					<a
							v-if="loggedIn"
							class="inline-block hover:bg-secondary hover:text-white py-2 px-4"
							href="/customer/account/"
					>
						Übersicht
					</a>
					<a
							v-if="loggedIn"
							class="inline-block hover:bg-secondary hover:text-white py-2 px-4"
							href="/customer/account/edit"
					>
						Kontodaten
					</a>
					<a
							v-if="loggedIn"
							class="inline-block hover:bg-secondary hover:text-white py-2 px-4"
							href="/customer/address"
					>
						Adressen
					</a>
					<a
							v-if="loggedIn"
							class="inline-block hover:bg-secondary hover:text-white py-2 px-4"
							href="/sales/order/history"
					>
						Meine Buchungen
					</a>
					<a
							v-if="loggedIn"
							class="inline-block hover:bg-secondary hover:text-white py-2 px-4"
							href="/review/customer"
					>
						Meine Bewertungen
					</a>
					<a
							v-if="loggedIn"
							:href="void 0"
							class="inline-block hover:bg-secondary hover:text-white py-2 px-4"
							@click="logout"
					>
						Logout
					</a>
					<a
							v-if="!loggedIn"
							class="inline-block hover:bg-secondary hover:text-white py-2 px-4"
							href="/customer/account/create/"
					>
						Registrieren
					</a>
					<a
							v-if="!loggedIn"
							class="inline-block hover:bg-secondary hover:text-white py-2 px-4"
							href="/customer/account/login/"
					>
						Login
					</a>
				</div>
			</div>
		</transition>
	</div>
</template>

<script lang="ts" setup>
import {directive as onClickaway} from 'vue3-click-away'
import {computed, inject, ref} from 'vue'
import {useStore} from "@/js/store/store"
import {ToastPluginApi} from "vue-toast-notification"

const toast = inject<ToastPluginApi>('toast')!

const store = useStore()
const vOnClickaway = onClickaway

const menuOpen = ref<boolean>(false)

const loggedIn = computed((): boolean => store.getters['loggedIn'])

const closeMenu = (): void => {
	if (menuOpen.value) {
		menuOpen.value = false
	}
}

const logout = async () => {
	try {
		await store.dispatch('logout')
		toast.success(
				'Sie wurden erfolgreich ausgeloggt. Sie werden in wenigen Sekunden zur Startseite weitergeleitet...',
				{
					onDismiss: function () {
						window.location.href = '/'
					},
				}
		)
	} catch (e) {
		toast.error('Beim Ausloggen ist ein Fehler aufgetreten.')
	}
}
</script>
