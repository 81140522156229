<template>
	<div class="tw-block">
		<button
				:class="isOpen ? 'outline-none ring-2 ring-offset-2 ring-secondary' : ''"
				class="relative group p-2 bg-white rounded-full text-primary inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
				type="button"
				@click="isOpen = !isOpen"
		>
			<svg
					:class="isOpen ? 'text-secondary' : 'text-primary'"
					class="h-5 w-5 group-hover:text-secondary"
					fill="currentColor"
					viewBox="0 0 20 20"
					xmlns="http://www.w3.org/2000/svg"
			>
				<path
						d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
				/>
			</svg>
			<div v-if="!isEmpty" class="absolute -top-1 -right-1">
				<svg
						class="text-red-500 w-4 h-4"
						viewBox="0 0 20 20"
						xmlns="http://www.w3.org/2000/svg"
						xmlns:xlink="http://www.w3.org/1999/xlink"
				>
					<circle class="fill-current" cx="10" cy="10" r="10"/>
					<text
							class="text-white fill-current"
							font-size="smaller"
							text-anchor="middle"
							x="10"
							y="14.5"
					>
						{{ itemsCount }}
					</text>
				</svg>
			</div>
		</button>
		<transition
				enter-active-class="transition ease-out duration-150"
				enter-class="opacity-0 translate-y-1"
				enter-to-class="opacity-100 translate-y-0"
				leave-active-class="transition ease-in duration-180"
				leave-class="opacity-100 translate-y-0"
				leave-to-class="opacity-0 translate-y-1"
		>
			<div
					v-if="isOpen"
					v-on-clickaway="closeCart"
					class="absolute z-10 transform mt-1 px-2 w-full w-screen md:max-w-sm inset-x-0 md:inset-x-auto md:right-0 sm:px-0"
			>
				<div
						class="shadow-lg border border-header overflow-hidden text-defaulttext bg-white"
				>
					<div class="relative p-4">
						<h4 class="text-lg mb-2">Ihr Warenkorb</h4>
						<CartItems></CartItems>
						<div class="my-4 pt-4 border-t flex justify-between">
							<div class="link" @click="closeCart">Schliessen</div>
							<div v-if="!isEmpty">
								<a :href="cart.config.checkout_url" class="button">
									Buchung jetzt abschliessen
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script lang="ts" setup>
import CartItems from "./topcart/CartItems.vue"
import {directive as onClickaway} from "vue3-click-away"
import {useStore} from "@/js/store/store"
import {computed, onMounted, ref} from "vue"
import {CartStoreInterface} from "@/js/interfaces/vuex"

const vOnClickaway = onClickaway

const store = useStore()
const cart = computed((): CartStoreInterface => store.getters["cart"])
const isEmpty = computed((): boolean => store.getters["cartEmpty"])
const itemsCount = computed((): number => store.getters["cartItemsCount"])

const isOpen = ref<boolean>(false)

const closeCart = () => {
	isOpen.value = false
}

onMounted(async () => {
	if (cart.value.config) {
		await store.dispatch("reloadCart")
		await store.dispatch("reloadCustomer")
	}
})
</script>
