<script lang="ts" setup>
import Share from "../../social/Share.vue"
import AddTicketForm from "./AddTicketForm.vue"
import {VueAddToCartConfigInterface} from "@/js/interfaces/cart"
import {BookTicketPostDataInterface} from "@/js/interfaces/ticket"
import {inject} from "vue"
import {ToastPluginApi} from "vue-toast-notification"
import * as Sentry from "@sentry/vue"
import axios from "axios"
import {useStore} from "@/js/store/store"

const props = defineProps<{ config: VueAddToCartConfigInterface; }>()

const store = useStore()

const toast = inject<ToastPluginApi>("toast")!

const book = async (ticket) => {
	const url = props.config.submit_url
	const bookingProps: BookTicketPostDataInterface = {
		ticket: ticket,
		submitUrl: url
	}
	let waitToast = toast.info(
			"Ihre Anmeldung wird zur Buchung hinzugefügt. Bitte warten...",
			{
				duration: 0
			}
	)
	try {
		await store.dispatch("bookTicket", bookingProps)
		waitToast.dismiss()
		const checkoutUrl = store.getters["cartConfig"].checkout_url
		toast.success(
				"Die Anmeldung ist jetzt im Warenkorb. Sie werden in wenigen Sekunden zum Abschluss der Buchung weitergeleitet...",
				{
					onDismiss: function () {
						window.location.href = checkoutUrl
					},
				}
		)
		setTimeout(() => {
			window.location.href = checkoutUrl
		})
	} catch (e) {
		Sentry.captureException(e)
		if (axios.isAxiosError(e)) {
			if (e.response) {
				if (e.response.status === 409) {
					waitToast.dismiss()
					toast.error(e.response.data.message,
							{
								duration: 10000
							}
					)
					return
				}
				if (e.response.status === 410) {
					waitToast.dismiss()
					toast.error("Ihre Sitzung ist abgelaufen. Bitte laden Sie die Seite neu und versuchen Sie es erneut.",
							{
								duration: 5000
							}
					)
					return
				}
			}
		}

		waitToast.dismiss()
		toast.error("Fehler bei der Verarbeitung. Bitte kontaktieren Sie uns über das Kontaktformular oder per Telefon.",
				{
					duration: 5000
				}
		)
	}
}

</script>
<template>
	<div class="bg-secondary-light shadow-md border border-secondary mb-6">
		<div class="p-4 bg-secondary flex justify-between">
			<div class="text-white text-lg bold uppercase">Anmeldung</div>
			<div>
				<Share></Share>
			</div>
		</div>
		<div class="p-4">
			<AddTicketForm :config="props.config" @bookTicket="book"></AddTicketForm>
		</div>
	</div>
</template>
<style>
.v-toast__text a {
	@apply text-white underline font-semibold;
}
</style>
