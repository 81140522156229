<template>
	<div class="w-full">
		<div
				class="flex w-full border border-inputborder focus-within:ring-1 focus-within:ring-inputfocusborder focus-within:border-inputfocusborder">
			<input
					v-model="street[0]"
					class="w-full border-0"
					placeholder="Hauptstrasse"
					type="text"
			/>
			<button
					v-if="!secondShown"
					class="focus:ml-px relative inline-flex items-center space-x-2 px-4 py-2 text-sm font-medium text-gray-500 bg-gray-50 hover:bg-gray-100 focus:outline-none"
					@click.stop.prevent="showSecondLine"
			>
				<svg
						class="h-5 w-5 text-gray-400"
						fill="none"
						stroke="currentColor"
						viewBox="0 0 24 24"
						xmlns="http://www.w3.org/2000/svg"
				>
					<path
							d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="2"
					/>
				</svg>
			</button>
			<button
					v-else
					class="focus:ml-px relative inline-flex items-center space-x-2 px-4 py-2 text-sm font-medium text-gray-500 bg-gray-50 hover:bg-gray-100 focus:outline-none"
					@click.stop.prevent="hideSecond()"
			>
				<svg
						class="h-5 w-5"
						fill="none"
						stroke="currentColor"
						viewBox="0 0 24 24"
						xmlns="http://www.w3.org/2000/svg"
				>
					<path
							d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="2"
					/>
				</svg>
			</button>
		</div>
		<ErrorMessagesDisplay :error="props.error"></ErrorMessagesDisplay>
		<div
				v-if="secondShown"
				class="flex w-full border border-inputborder focus-within:ring-1 focus-within:ring-inputfocusborder focus-within:border-inputfocusborder">
			<input
					v-model="street[1]"
					class="w-full border-0"
					placeholder="Postfach"
					type="text"
			/>
		</div>
	</div>
</template>

<script lang="ts" setup>
import {computed, ref, watch} from "vue"
import ErrorMessagesDisplay from "@/js/components/ui/ErrorMessagesDisplay.vue"

const props = defineProps<{ modelValue?: string[], error?: string }>()
const emit = defineEmits(['update:modelValue'])


const street = ref<string[]>([])

const valueProp = computed(() => {
	return props.modelValue
})

watch(valueProp, (value) => {
	if (Array.isArray(value) && value !== street.value) {
		street.value = value
	}
}, {immediate: true, deep: true})

watch(street, (value) => {
	if (Array.isArray(value) && value[0] === '') {
		value = []
	}
	emit("update:modelValue", value)
}, {deep: true})


const secondShown = ref<boolean>(false)
const showSecondLine = () => {
	street.value.push("")
	secondShown.value = true
}
const hideSecond = () => {
	secondShown.value = false
	if (street.value.length > 1) {
		street.value.splice(1, 1)
	}
}


</script>
