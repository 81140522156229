<template>
	<div>
		<Checkbox
				v-for="option in options"
				:id="option.id"
				:key="option.id"
				:model-value="selected[option.id]"
				:name="uuid"
				@update:modelValue="updateCheckbox(option.id)"
		>
			<div class="flex justify-between">
				<div>{{ option.title }}</div>
			</div>
			<template v-slot:comment>
				{{ option.comment }}
			</template>
		</Checkbox>
	</div>
</template>

<script lang="ts" setup>
import Checkbox from "./Checkbox.vue"
import {nextTick, onMounted, ref, watchEffect} from 'vue'
import {CheckboxOptionInterface} from "@/js/interfaces/checkbox"

const props = defineProps<{
	options: Array<CheckboxOptionInterface>
	modelValue?: Array<string>
}>()

const emit = defineEmits(['update:modelValue'])

const selected = ref({})

const uuid = ref<null | number>(null)

const initCheckboxGroup = () => {
	props.options.forEach((value) => {
		selected.value[value.id] = false
	})
	uuid.value = Math.random()
	nextTick(() => {
		emitSelected()
	})
}

const updateCheckbox = (id: number | string) => {
	id = id.toString()
	for (let key in selected.value) {
		if (key.toString() === id) {
			selected.value[key] = !selected.value[key]
		}
	}
	emitSelected()
}

const emitSelected = () => {
	let selectedArray = [] as string[]
	for (const id in selected.value) {
		if (selected.value[id] === true) {
			selectedArray.push(id)
		}
	}
	emit('update:modelValue', selectedArray)
}

watchEffect(() => {
	if (props.modelValue) {
		for (const id in selected.value) {
			selected.value[id] = props.modelValue.includes(id)
		}
	}
})

onMounted(() => {
	initCheckboxGroup()
})
</script>
