<template>
	<div v-if="value && value.entity_id">
		<UiModal>
			<template v-slot:opener>
				<button class="button-orange w-full">
          <span v-if="iconDisplayClass" class="icon">
            <font-awesome-icon icon="exclamation-circle"></font-awesome-icon>
          </span>
					{{ value.linktext }}
				</button>
			</template>
			<template v-slot:title>{{ value.title }}</template>
			<div class="std">
				<div v-html="value.alert"></div>
			</div>
		</UiModal>
	</div>
</template>

<script lang="ts" setup>
import UiModal from "../ui/UiModal.vue"
import {computed} from "vue"

interface AlertValueInterface {
	entity_id?: number
	title: string
	linktext: string
	alert: string
}

defineProps<{
	value?: AlertValueInterface
}>()

const iconDisplayClass = computed(() => {
	return "fas fa-exclamation-circle"
})
</script>
