<template>
	<div v-bind="$attrs" @click="scrollToElement">
		<slot></slot>
	</div>
</template>

<script lang="ts" setup>
const props = defineProps<{
	elementId: string
}>()

const scrollToElement = () => {
	let element = document.querySelector("#" + props.elementId)
	if (!element) {
		return
	}
	element.scrollIntoView({
		behavior: "smooth",
		block: 'start'
	})
}
</script>
