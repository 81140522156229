<template>
	<div v-if="loading" class="width-full text-center p-12">
		<PulseLoader :color="hexColor" :size="size"></PulseLoader>
	</div>
</template>

<script lang="ts" setup>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

withDefaults(defineProps<{
	loading: boolean
	hexColor: string
	size: string
}>(), {
	loading: true,
	hexColor: "blue-500",
	size: "15px",
})

defineExpose({
	PulseLoader
})
</script>
