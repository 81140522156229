<template>
	<div
			:class="
      modelValue
        ? 'bg-' +
          twColorBgSelected +
          ' border-' +
          twColorBorderSelected +
          ' z-10'
        : 'bg-' + twColorBgUnselected + ' border-' + twColorBorderUnselected
    "
			class="relative border p-4 flex"
	>
		<div class="flex items-center h-5">
			<input
					:id="'option-' + id"
					:checked="modelValue === true"
					:class="
          modelValue
            ? 'border-' + twColorSelected + ' text-' + twColorSelected
            : 'border-' + twColorUnselected + ' text-' + twColorUnselected
        "
					class="form-checkbox h-4 w-4 transition duration-150 ease-in-out cursor-pointer"
					data-testid="checkbox-input"
					type="checkbox"
					@click.stop="emitChecked(!modelValue)"
			/>
		</div>
		<label
				class="ml-3 flex flex-col cursor-pointer w-full h-full"
				data-testid="label"
				style="display: block"
				@click.stop="emitChecked(!modelValue)"
		>
      <span
		      :class="modelValue ? 'text-' + twColorSelected : 'text-' + twColorUnselected"
		      class="text-sm leading-5 font-semibold"
      >
        <slot></slot>
      </span>
			<span
					:class="
          modelValue
            ? 'text-' + twColorCommentSelected
            : 'text-' + twColorCommentUnselected
        "
					class="text-sm leading-5 font-normal"
			>
        <slot name="comment"></slot>
      </span>
		</label>
	</div>
</template>

<script lang="ts" setup>

withDefaults(defineProps<{
	modelValue?: boolean
	id: string | number
	twColorBgSelected?: string
	twColorBgUnselected?: string
	twColorBorderSelected?: string
	twColorBorderUnselected?: string
	twColorSelected?: string
	twColorUnselected?: string
	twColorCommentSelected?: string
	twColorCommentUnselected?: string
}>(), {
	modelValue: false,
	id: '',
	twColorBgSelected: 'white',
	twColorBgUnselected: 'white',
	twColorBorderSelected: 'blue-500',
	twColorBorderUnselected: 'gray-300',
	twColorSelected: 'primary',
	twColorUnselected: 'gray-500',
	twColorCommentSelected: 'blue-700',
	twColorCommentUnselected: 'gray-500',
})

const emit = defineEmits(['update:modelValue'])

const emitChecked = (value: boolean) => {
	emit('update:modelValue', value)
}
</script>
